import React, { Fragment } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Testimonial from "../slider/testimonial";
import BlogSlider from "../slider/BlogSlider";
import WhyUs from "../slider/WhyUs";
const assets = '/assets';

const NewYork = () => {


    return (
        <Fragment>
            <Header />


            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">New York Ryzen RDP</h3>
                                    <p className="description b1">Buy New York Ryzen RDP: Affordable with admin access. <br /> Get Cheap New York Ryzen RDP for reliable, efficient connections today.</p>
                                    <ul className="page-list">
                                        <li className="rainbow-breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="rainbow-breadcrumb-item"><a href="/">Private RDP</a></li>
                                        <li className="rainbow-breadcrumb-item active">New York Ryzen RDP</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">

                        {/* <!-- Pricing Part --> */}
                        <div className="wrapper rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Pricing</span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our product</h2>
                                            <p className="description b1">Priced to fit your specific needs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row--15">

                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">New York Ryzen RDP #1</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$7.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$12.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Ryzen 5950X Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i>1 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 1Gb DDR4 RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 25Gb NVMe
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location: USA-New York
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave active">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-two">New York Ryzen RDP #2</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$11.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$19.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Ryzen 5950X Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i>2 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 2Gb DDR4 RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 40Gb NVMe 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location: USA-New York
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-waveform-lines"></i>
                                                        </div>
                                                        <h4 className="title color-var-three">New York Ryzen RDP #3</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$14.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$24.99 </span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Ryzen 5950X Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 3 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 3Gb DDR4 RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 60Gb NVMe 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location: USA-New York
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">New York Ryzen RDP #4</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$17.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$29.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Ryzen 5950X Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 3 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 4Gb DDR4 RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 80Gb NVMe 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location: USA-New York
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave active">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-two">New York Ryzen RDP #5</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$26.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$34.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Ryzen 5950X Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i>4 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 6Gb DDR4 RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 100Gb NVMe 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location: USA-New York
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-waveform-lines"></i>
                                                        </div>
                                                        <h4 className="title color-var-three">New York Ryzen RDP #6</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$33.99  </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$44.99 </span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Ryzen 5950X Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 6 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 8Gb DDR4 RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 120Gb NVMe 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location: USA-New York
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">New York Ryzen RDP #7</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$44.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$99.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Ryzen 5950X Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 6 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 12Gb DDR4 RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 160Gb NVMe 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location: USA-New York
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave active">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-two">New York Ryzen RDP #8</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$59.99  </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$129</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Ryzen 5950X Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i>6 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 16Gb DDR4 RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 200Gb NVMe 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location: USA-New York
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-waveform-lines"></i>
                                                        </div>
                                                        <h4 className="title color-var-three">New York Ryzen RDP #9</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$89.99   </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$179 </span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Ryzen 5950X Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 6 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 32Gb DDR4 RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 250Gb NVMe 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location: USA-New York
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center pb--60 sal-animate" data-sal="slide-up"  data-sal-duration="700"
                                        data-sal-delay="100">
                                        <h4 className="subtitle">
                                            <span className="theme-gradient">We always try to maintain quality</span>
                                        </h4>
                                        <h2 className="title mb--0"> Why Choose Us</h2>
                                        <h5>Pricing , focusing on speed, security, and guaranteed uptime.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <WhyUs />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}

                       

                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Join our Experts community</h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/">Join Now</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400"
                                            data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Blogs</span>
                                            </h4>
                                            <h2 className="title mb--60">Explore Our Insights</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <BlogSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}



                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}
            </div>
            {/* <!-- Start Pricing Area  --> */}



            <Footer />

        </Fragment>
    );
}

export default NewYork;