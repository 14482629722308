import React, { Fragment } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Testimonial from "../slider/testimonial";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'sal.js/dist/sal.css';
import BlogSlider from "../slider/BlogSlider";
const assets = '/assets';


function Refund() {
  return (
    <Fragment>
    <Header />

        {/* <!-- Start Pricing Area  --> */}
        <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">Policies</h3>
                                    <p className="description b1">Please go through our policies before buying any plan</p>
                                    <ul className="page-list">
                                        <li className="rainbow-breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="rainbow-breadcrumb-item active">Refund Policies</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">

                        {/* <!-- Start Tab__Style--one Area  --> */}
                        <div className="rainbow-service-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center pb--60" >
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">1 January 2020</span>
                                            </h4>
                                            <h2 className="title mb--0">Refund Policies DigiRDP</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row--30 align-items-center">
                                    <div className="col-lg-12">
                                        <div>
                                            <h4>Refund Policy and Service Guidelines</h4>
                                            <p>By registering with DIGIRDP or subscribing to our services, you acknowledge and agree to our refund policy. This policy has been established to enhance your user experience and is governed by specific guidelines and requirements. We kindly request that you carefully review this policy to prevent any potential issues. Please be aware that this policy is subject to change at any time, with or without notice.</p>

                                            <h4>Refund Requests</h4>
                                            <p>Our refund policy outlines the procedures for requesting and receiving refunds for our services. To process a refund for RDP plans, a valid reason is required. In cases where a valid reason is provided, we will make efforts to resolve the issue or issue a refund. However, we are not obligated to provide a refund if you have violated our terms of service.</p>

                                            <h4>Refund Ineligibility</h4>
                                            <p>Refunds will not be accepted for the following reasons :</p>
                                            <ol>
                                                <li>Ignorance about the proper usage of RDP.</li>
                                                <li>Unsuitable network connections at the client's end.</li>
                                                <li>Technical issues at the client's end that prevent optimal product usage.</li>
                                                <li>Issues related to third-party software or websites. (Clients should check the respective website requirements before purchasing to ensure compatibility with their goals.)</li>
                                                <li>Products purchased with a 20% or above discounted promo code or during promotional events like Black Friday sales.</li>
                                                <li>Any third-party website not functioning as expected, for which we do not provide guarantees.</li>
                                            </ol>

                                            <h4>Prohibited Activities</h4>
                                            <p>We strictly prohibit activities such as Hacking, Brute-forcing, and the use of Mass Mailing Tools, as they are illegal. Clients are not allowed to use these activities with the servers provided by DIGIRDP.</p>

                                            <h4>Residential RDP ISP/Proxy Fee Policy</h4>
                                            <p>There is no refund policy for our Residential RDP ISP and proxy fees. In cases where a refund is warranted, it will be issued after deducting the ISP/Proxy cost, which varies based on the plan and starts at $9.99. If any proxy or ISP issues are identified, we will address them promptly, depending on the issue and holiday schedule.</p>

                                            <h4>Server Setup</h4>
                                            <p>We have specified setup times for all products. Claims regarding server delays or non-delivery will not be accepted within the setup time.</p>

                                            <h4>Payment and Auto-Renewal</h4>
                                            <ul>
                                                <li>Digirdp Add Funds/Credit Funds are non-refundable at any cost.</li>
                                                <li>Stripe automatically adds/saves client credit/debit card information for auto-renewal. Clients can remove this information from the DIGIRDP client area. If our system auto-charges your card, you can claim a refund within 24 hours of the auto-charge, with a deduction of any VAT/tax/payment gateway fee.</li>
                                            </ul>

                                            <h4>Important Notes</h4>
                                            <ul>
                                                <li>If you violate our Terms of Service, no refund will be provided.</li>
                                                <li>Cancellation requests made without prior support contact or within 24 hours of creating a support ticket will not be eligible for a refund.</li>
                                                <li>You agreed to our Terms of Service and Refund Policy during payment. Payment providers such as PayPal or Payza also follow our policies for refunds.</li>
                                                <li>If you do not agree with the above rules and restrictions, please refrain from placing a Server or RDP order.</li>
                                            </ul>

                                            <h6>For more details, please refer to our <a href="/terms" style={{ textDecoration: "underline" }}>Terms of Service</a> .</h6>
                                            <p>Thank you for choosing DIGIRDP.</p>
                                            <h5><b>TEAM DIGIRDP</b></h5>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                         {/* <!-- Start Features Area --> */}
                         <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our Plans</h2>
                                            <p>There are several plans which includes Windows RDP, Cloud VPS and Dedicated <br /> servers which will make the deployement of your application more easier</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt--60">
                                        <div className="advance-tab-button advance-tab-button-1 right-top">
                                            <ul className="nav nav-tabs tab-button-list">

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button ">
                                                        <div className="tab">
                                                            <h2 className="title">240 + </h2>
                                                            <p className="description sal-animate">Windows RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">110 +</h2>
                                                            <p className="description sal-animate">Cloud VPS Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">59 +</h2>
                                                            <p className="description sal-animate">Dedicated Server Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">114 +</h2>
                                                            <p className="description sal-animate">Private RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="view-more-button text-center mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                        <a className="btn-default color-blacked" href="/contact">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta mt-5">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Join our Experts community</h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/contact">Join DigiRDP Experts</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400"
                                            data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Blogs</span>
                                            </h4>
                                            <h2 className="title mb--60">Explore Our Insights</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <BlogSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}



                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}
            </div>
            {/* <!-- Start Pricing Area  --> */}
        

    <Footer />
    </Fragment>
  );
}
export default Refund;