import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import rocketAnimation from '../../rocket.json';
const assets = '/assets';

function Location () {
    
    const options = {
        loop: true,
        autoplay: true, 
        animationData: rocketAnimation, 
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    return (
        <Fragment>

            {/* <!-- location  --> */}
            <div className="rainbow-testimonial-area mt-5">
                <div className="video-background">
                    <video autoPlay loop muted playsInline className="video">
                        <source src={`${assets}/images/bg-video/map.mp4`} type="video/mp4" />
                    </video>
                    
                    <div className="overlay container">
                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                            <h4 className="subtitle">
                                <span className="text-white">Service Locations</span>
                            </h4>
                            <h2 className="title text-white">Explore Our Global Service Locations <Lottie options={options} height={'auto'} width={233} style={{ margin: "0", position: "absolute", top: "0", right: "0", transform: "rotate(45deg)" }} /></h2>
                        </div>
                        <div class="dc-list">
                            <Link to="#" className='n'>USA</Link>
                            <Link to="#" className='n'>UK</Link>
                            <Link to="#" className='n'>Netherlands</Link>
                            <Link to="#" className='n'>India</Link>
                        </div>
                        <div className="map-new">
                            <img src={`${assets}/images/added/World_map_with_points.svg`} alt='img' />
                        </div>
                    </div>
                </div>
            </div>
            
        </Fragment>
    )
}

export default Location;
