import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
// import $ from 'jquery';
import Testimonial from "../slider/testimonial";
import BlogSlider from "../slider/BlogSlider";
import WhyUs from "../slider/WhyUs";
import axios from "axios";
import { useParams } from "react-router-dom";
import Promo from "../slider/Promo";
import Location from "../sales-page/Location";
const assets = '/assets';

const RDPPlan = () => {
    const [rdp, setRDP] = useState([]);
    const [rdpplans, setRDPplans] = useState([]);
    const [rdpfaqs, setRDPFAQS] = useState([]);
    const { url_text } = useParams();
    const [loading, setLoading] = useState(true);
    console.log(url_text);
    useEffect(() => {
        const rdplocation_plan = async () => {
            setLoading(true); 
            try {
                const response = await axios.get(`https://admin.digirdp.chaipost.co.in/api/rdp_plans/${url_text}`);
                console.log("rdp API Response:", response.data[0]);
                console.log("rdp plan API Response:", response.data[1]);
                console.log("rdp plan API Response:", response.data[2]);
                setRDP(response.data[0]);
                setRDPplans(response.data[1]);
                setRDPFAQS(response.data[2]);
            } catch (error) {
                console.error("Error fetching rdplocation plan data:", error);
            }
            finally{
                setLoading(false);
            }
        };
        if(url_text){
            rdplocation_plan();
        }
    },[url_text]);


    

    return (
        <Fragment>
            <Header />


            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">{rdp.name}</h3>
                                    <p className="description b1">{rdp.description}</p>
                                    <ul className="page-list">
                                        <li className="rainbow-breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="rainbow-breadcrumb-item"><a href="/">Windows RDP</a></li>
                                        <li className="rainbow-breadcrumb-item active">{rdp.name}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">

                        <Promo />

                        {/* <!-- Pricing Part --> */}
                        <div className="wrapper rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Pricing</span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our product</h2>
                                            <p className="description b1">Priced to fit your specific needs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row--15">
                                {rdpplans.map((rdpplan, index) => (
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <h4 className="title color-var-one">{rdpplan.name}</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">${rdpplan.offer_price}</span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>{rdpplan.price}</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-footer">
                                                        <a className="btn-default" href="/">Buy Now</a>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> {rdpplan.users} User
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> {rdpplan.processor}
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> {rdpplan.cpu} CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> {rdpplan.traffic}
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> {rdpplan.os}
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> {rdpplan.ram} RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth {rdpplan.bandwidth}
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address {rdpplan.ip}
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> {rdpplan.drives}
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> {rdpplan.uptime} uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> {rdpplan.description}
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> {rdpplan.free}
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                 ))}
                                    {/* <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave active">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-two">Canada Admin RDP #2</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$11.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$19.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> AMD EPYC Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i>2 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 2Gb RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 40Gb NVMe SSD
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge">Popular</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-waveform-lines"></i>
                                                        </div>
                                                        <h4 className="title color-var-three">Canada Admin RDP #3</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$14.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$24.99 </span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> AMD EPYC Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 3 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 3Gb RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 60Gb NVMe SSD
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">Canada Admin RDP #4</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$17.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$29.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> AMD EPYC Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 3 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 4Gb RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 80Gb NVMe SSD
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge">Popular</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave active">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-two">Canada Admin RDP #5</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$26.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$34.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> AMD EPYC Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i>4 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 6Gb RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 100Gb NVMe SSD
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-waveform-lines"></i>
                                                        </div>
                                                        <h4 className="title color-var-three">Canada Admin RDP #6</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$33.99  </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$44.99 </span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> AMD EPYC Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 6 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 8Gb RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 120Gb NVMe SSD
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge">Popular</div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">Canada Admin RDP #7</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$44.99 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$99.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> AMD EPYC Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 6 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 12Gb RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 160Gb NVMe SSD
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave active">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-two">Canada Admin RDP #8</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$59.99  </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$129</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> AMD EPYC Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i>6 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 16Gb RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 200Gb NVMe SSD
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge">Popular</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-waveform-lines"></i>
                                                        </div>
                                                        <h4 className="title color-var-three">Canada Admin RDP #9</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$89.99   </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$179 </span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> AMD EPYC Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 6 core CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Windows or Linux
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 32Gb RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1Gbps Metered
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 Dedicated IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 250Gb NVMe SSD
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Admin Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }}>limitedEdition</div>
                                        </div>
                                    </div>   */}
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center pb--60 sal-animate" data-sal="slide-up"  data-sal-duration="700"
                                        data-sal-delay="100">
                                        <h4 className="subtitle">
                                            <span className="theme-gradient">We always try to maintain quality</span>
                                        </h4>
                                        <h2 className="title mb--0"> Why Choose Us</h2>
                                        <h5>Pricing , focusing on speed, security, and guaranteed uptime.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <WhyUs />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}

                        {/* <!-- Start Accordion Area --> */}
                        {rdpfaqs && rdpfaqs.length > 0 ? (
                        <div className="rainbow-accordion-area rainbow-section-gap">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-lg-12 col-xl-4 col-12">
                                        <div className="split-inner">
                                            <h2 className="title sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="200">Any question's? we have answers!</h2>
                                            <p className="description sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Don’t find your answer here? just send us a message for any query.</p>
                                            <div className="contact-button mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                                <a className="rainbow-gradient-btn without-shape" target="_blank" href="/contact"><span>Contact us</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-7 col-12">
                                        <div className="rainbow-accordion-style rainbow-accordion-02 accordion">
                                        
                                            <div className="accordion" id="accordionExampleb">
                                                {rdpfaqs.map((rdpfaq, index)=> (
                                                <div key={index} className="accordion-item card">
                                                    <h2 className="accordion-header card-header"  id={`heading-${rdpfaq.id}`}>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${rdpfaq.id}`} aria-expanded="false" aria-controls={`collapse-${rdpfaq.id}`}>
                                                            {rdpfaq.question}
                                                        </button>
                                                    </h2>
                                                    <div id={`collapse-${rdpfaq.id}`} className="accordion-collapse collapse" aria-labelledby={`heading-${rdpfaq.id}`} data-bs-parent="#accordionExampleb">
                                                        <div className="accordion-body card-body">
                                                            {rdpfaq.answer}
                                                        </div>
                                                    </div>
                                                </div>
                                                ))}
                                                {/* <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="headingSix">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                            What are the benefits of using Canada Admin RDP?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExampleb">
                                                        <div className="accordion-body card-body">
                                                            Some of the key benefits include: <br /> • High-speed connectivity and low latency for users in North America. <br /> • Enhanced security features to protect your data and operations. <br /> • Full administrative access to install software, manage settings, and configure the server as per your requirements. <br /> • 24/7 customer support to assist with any technical issues. <br /> • Scalable resources to meet your growing needs.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="headingSeven">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">Who can benefit from Canada Admin RDP?</button>
                                                    </h2>
                                                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExampleb">
                                                        <div className="accordion-body card-body">Canada Admin RDP is beneficial for: <br /> • Businesses looking to manage their IT infrastructure remotely. <br /> • Freelancers and remote workers need a secure and powerful server. <br /> • Developers and IT professionals require a robust environment for testing and deployment. <br /> • Companies need a reliable server for hosting applications and websites.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="headingEight">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">How do I get started with Canada Admin RDP?</button>
                                                    </h2>
                                                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExampleb">
                                                        <div className="accordion-body card-body">Getting started is easy. Visit the DigiRDP website, select the Canada Admin RDP plan that best suits your needs, complete the purchase process, and you will receive your login credentials via email. Our support team is available to assist you with the setup if needed.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="headingNine">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">What security measures are in place for Canada Admin RDP?</button>
                                                    </h2>
                                                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExampleb">
                                                        <div className="accordion-body card-body">DigiRDP implements several security measures, including: <br /> • Strong encryption protocols to protect data transmission. <br /> • Regular security updates and patches. • Firewall protection to prevent unauthorized access. <br /> • DDoS protection to ensure server availability. <br /> • Regular backups to safeguard your data.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="headingTen">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">Can I upgrade or downgrade my Canada Admin RDP plan?</button>
                                                    </h2>
                                                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExampleb">
                                                        <div className="accordion-body card-body">Yes, DigiRDP offers flexible plans that can be easily upgraded or downgraded based on your requirements. You can contact our support team to assist with the process.</div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ) : (
                            ""
                        )}

                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta mt-5">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Join our Experts community</h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/contact">Join DigiRDP Experts</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400"
                                            data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Blogs</span>
                                            </h4>
                                            <h2 className="title mb--60">Explore Our Insights</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <BlogSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}



                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}
            </div>
            {/* <!-- Start Pricing Area  --> */}


            <Location />
            <Footer />

        </Fragment>
    );
}

export default RDPPlan;