import './App.css';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import React, { useEffect } from "react";

import Home from './pages/Home';
import CanadaRdp from './pages/rdp-plan/CanadaRdp';
import Rdp from './pages/rdp-plan/Rdp';
import RDPPlan from './pages/rdp-plan/RDPPlan';
import ContactUs from './pages/support/ContactUs';
import KnowledgeBase from './pages/support/KnowledgeBase';
import 'react-toastify/dist/ReactToastify.css';
import DedicatedPlan from './pages/dedicated-plan/DedicatedPlan';
import Dedicated from './pages/dedicated-plan/Dedicated';
import Blog from './pages/support/blog/Blog';
import BlogDetails from './pages/support/blog/BlogDetails';
import LoginWindows from './pages/support/blog/LoginWindows';
import Miami from './pages/private-rdp/Miami';
import Amazon from './pages/private-rdp/Amazon';
import Private from './pages/private-rdp/Private';
import PrivateRDP from './pages/private-rdp/PrivateRDP';
import NewYork from './pages/private-rdp/NewYork';
import Dallas from './pages/private-rdp/Dallas';
import Singapore from './pages/private-rdp/Singapore';
import Germany from './pages/private-rdp/Germany';
import Netherlands from './pages/private-rdp/Netherlands';
import Uk from './pages/private-rdp/Uk';
import CheapUk from './pages/private-rdp/CheapUk';
import CheapNetherlands from './pages/private-rdp/CheapNetherlands';
import Dating from './pages/private-rdp/Dating';
import Cloud from './pages/cloud-vps/Cloud';
import Cloudvps from './pages/cloud-vps/Cloudvps';
import NetherlandsCloud from './pages/cloud-vps/NetherlandsCloud';
import CanadaCloud from './pages/cloud-vps/CanadaCloud';
import EpycStorage from './pages/cloud-vps/EpycStorage';
import UkCloud from './pages/cloud-vps/UkCloud';
import IndianHosting from './pages/cloud-vps/IndianHosting';
import Residential from './pages/private-rdp/Residential';
import Buy from './pages/private-rdp/Buy';
import Privacy from './pages/policy/Privacy';
import Terms from './pages/policy/Terms';
import Refund from './pages/policy/Refund';
import AboutUs from './pages/AboutUs';
import Offer from './pages/sales-page/Offer';
import BlackFriday from './pages/sales-page/BlackFriday';
import DataCenter from './pages/sales-page/DataCenter';
import Affiliate from './pages/sales-page/Affiliate';
import GlassPage from './pages/GlassPage';
import Error from './pages/Error';



// import axios from './api/axios'


function App() {
    
    const ScrollToTop = () => {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    };



    return (
        <div className="App">
            <Router>
                <ScrollToTop />
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/about" exact element={<AboutUs />} />
                    <Route path="/contact" exact element={<ContactUs />} />
                    <Route path="/privacy" exact element={<Privacy />} />
                    <Route path="/terms" exact element={<Terms />} />
                    <Route path="/refund" exact element={<Refund />} />
                    <Route path="/offer" exact element={<Offer />} />
                    <Route path="/sales" exact element={<BlackFriday />} />
                    <Route path="/rdp-plan" exact element={<Rdp />} />
                    <Route path="/dedicated-server" exact element={<Dedicated />} />
                    <Route path="/cloud-vps" exact element={<Cloud />} />
                    <Route path="/private_rdp" exact element={<Private />} />
                    <Route path="/datacenter" exact element={<DataCenter />} />
                    <Route path="/affiliate" exact element={<Affiliate />} />
                    <Route path="/glass-looking" exact element={<GlassPage />} />
                    <Route path="/Offer" />

                    <Route path="/canada-admin-rdp" exact element={<CanadaRdp />} />
                    <Route path="/rdp-plan/:url_text" exact element={<RDPPlan />} />

                    <Route path="/dedicated-server/:url_text" exact element={<DedicatedPlan />} />
                    <Route path="/knowledge-base" exact element={<KnowledgeBase />} />
                    <Route path="/blog" exact element={<Blog />} />
                    <Route path="/blog-details/:slug" exact element={<BlogDetails />} />
                    <Route path="/how-to-login-to-windows" exact element={<LoginWindows />} />

                    <Route path="/miami-private-rdp" exact element={<Miami />} />
                    <Route path="/amazon-mturk-rdp" exact element={<Amazon />} />
                    <Route path="/private_rdp/:url_text" exact element={<PrivateRDP />} />
                    <Route path="/newyork-ryzen-rdp" exact element={<NewYork />} />
                    <Route path="/dallas-ryzen-rdp" exact element={<Dallas />} />
                    <Route path="/singapore-ryzen-rdp" exact element={<Singapore />} />
                    <Route path="/germany-ryzen-rdp" exact element={<Germany />} />
                    <Route path="/netherlands-ryzen-rdp" exact element={<Netherlands />} />
                    <Route path="/uk-ryzen-rdp" exact element={<Uk />} />
                    <Route path="/cheap-uk-rdp" exact element={<CheapUk />} />
                    <Route path="/netherlands-rdp" exact element={<CheapNetherlands />} />
                    <Route path="/buy-dating-rdp" exact element={<Dating />} />
                    <Route path="/residential-rdp" exact element={<Residential />} />
                    <Route path="/usa-rdp" exact element={<Buy />} />

                    <Route path="/cloud-vps/:url_text" exact element={<Cloudvps />} />
                    <Route path="/netherlands-cloud-vps" exact element={<NetherlandsCloud />} />
                    <Route path="/canada-cloud-vps" exact element={<CanadaCloud />} />
                    <Route path="/amd-epyc-storage-vps" exact element={<EpycStorage />} />
                    <Route path="/uk-cloud-vps" exact element={<UkCloud />} />
                    <Route path="/indian-amd-epyc-vps-hosting" exact element={<IndianHosting />} />
                    <Route path="*" element={<Error />} />
                    {/* <Route path="/cloud-vps/:url_text*" element={<Error />} />
                    <Route path="/private_rdp/:url_text*" element={<Error />} />
                    <Route path="/rdp-plan/:url_text*" element={<Error />} />
                    <Route path="/dedicated-server/:url_text*" element={<Error />} /> */}
                </Routes>
            </Router>
        </div>
    );
}

export default App;
