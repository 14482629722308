import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
const assets = '/assets';

const Categories = () => {

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Default for larger screens
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        arrows: false,
        responsive: [
            {
                breakpoint: 768, // Mobile view breakpoint
                settings: {
                    slidesToShow: 1, // Show 1 slide on mobile
                    slidesToScroll: 1, // Scroll 1 slide at a time
                },
            },
        ],
    };




    return (
        <Fragment>

            <Slider {...sliderSettings}>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                    <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/in.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">India Ryzen 7950x RDP</h5>
                                    <p className="m-0">Buy Indian AMD EPYC RDP: Indian AMD EPYC RDP with high speed internet full administrator access 1Gbps internet speed and many more.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/admin.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">Canada Admin RDP</h5>
                                    <p className="m-0">Buy Cheap Canada Admin RDP with full admin access. Get the best USA RDP solutions. Secure connections for your needs. Buy now!</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/amd.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">AMD EPYC Storage RDP</h5>
                                    <p className="m-0">Buy AMD EPYC Storage RDP: AMD EPYC Storage RDP with high speed internet full administrator access 1Gbps internet speed and many more.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/server.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">Australia Dedicated Server</h5>
                                    <p className="m-0">Buy Australia Dedicated Server - Get reliable performance at affordable rates with our cheap Australia Dedicated Server solutions. Buy now!</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/server.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">Storage Dedicated Server</h5>
                                    <p className="m-0">Get a Cheap Storage Dedicated Server today! Buy reliable storage solutions for your data needs. Powerful, affordable, and dedicated to your success.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/server.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">Instant Dedicated Server</h5>
                                    <p className="m-0">Buy Cheap Instant Dedicated Servers for immediate deployment. Get affordable, dedicated solutions tailored to your needs instantly.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/in.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">India Ryzen 7950x VPS</h5>
                                    <p className="m-0">Discover Premium VPS Hosting, Starting at Just $4/month! Lightning-fast setup, 99.9% uptime, full root access, and a dedicated control panel. Join us now!</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/germany.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">Germany Cloud VPS</h5>
                                    <p className="m-0">Discover top-quality Germany Cloud VPS SSD hosting at unbeatable prices. Secure, reliable, and blazing-fast Germany SSD hosting servers available now!</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/amd.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">AMD EPYC Storage VPS</h5>
                                    <p className="m-0">Buy AMD EPYC Storage VPS: AMD EPYC Storage VPS with high speed internet full administrator access 1Gbps internet speed and many more.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/us.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">Miami Private RDP</h5>
                                    <p className="m-0">Buy Miami Private RDP: Access affordable, reliable servers with our Cheap Miami Private RDP options. Secure, efficient connections guaranteed.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/us.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">Amazon Mturk RDP</h5>
                                    <p className="m-0">Buy Amazon Mturk RDP: Affordable and secure with admin access. Get your Cheap Amazon Mturk RDP for reliable connections.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-box-card active card-style-default testimonial-style-defalt has-bg-shaped">
                        <a href="/">
                            <div className="inner inner-new">
                                <div className="content">
                                <div className="bottom-content">
                                        <div className="meta-info-section">
                                            <div className="desc-img">
                                                <img src="assets/images/added/us.svg" alt="brang" />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="pt-5">New York Ryzen RDP</h5>
                                    <p className="m-0">Buy New York Ryzen RDP: Affordable with admin access. Get Cheap New York Ryzen RDP for reliable, efficient connections today.</p>
                                </div>
                            </div>
                        </a>    
                    </div>
                </div>
            </Slider>
            {/* <!-- End Testimonial Area  --> */}

        </Fragment>
    );
}

export default Categories;
