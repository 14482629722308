import React, { Fragment } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Testimonial from "../slider/testimonial";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'sal.js/dist/sal.css';
import BlogSlider from "../slider/BlogSlider";
const assets = '/assets';


function Terms() {
  return (
    <Fragment>
    <Header />

        {/* <!-- Start Pricing Area  --> */}
        <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">Policies</h3>
                                    <p className="description b1">Please go through our policies before buying any plan</p>
                                    <ul className="page-list">
                                        <li className="rainbow-breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="rainbow-breadcrumb-item active">Terms and Conditions</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">

                        {/* <!-- Start Tab__Style--one Area  --> */}
                        <div className="rainbow-service-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center pb--60" >
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">1 January 2020</span>
                                            </h4>
                                            <h2 className="title mb--0">Welcome to DigiRDP Terms & conditions</h2>
                                        </div>
                                    </div>
                                </div>

                                

                                <div className="row row--30 align-items-center">
                                    <div className="col-lg-12">
                                        <div>
                                            <h4>Service Agreement</h4>
                                            <p>This Service Agreement ("Agreement") outlines the terms and conditions governing the use of DigiRDP's services ("Service") by the subscriber ("Subscriber"). By accessing or using the Service, the Subscriber agrees to be bound by the terms and conditions of this Agreement. If the Subscriber does not agree to the terms and conditions of this Agreement, they must not access or use the Service.</p>

                                            <h4>Provision of Service</h4>
                                            <p>DigiRDP will provide the Subscriber with access to the Service as described in the order form or subscription agreement. This includes maintenance of the hardware and network infrastructure necessary for Internet connection and a password for authentication during use. The Subscriber does not have any rights or title to DigiRDP's equipment or software or any third-party equipment used by DigiRDP to provide the Service.</p>

                                            <h4>Payment</h4>
                                            <p>The Subscriber agrees to pay all fees invoiced by DigiRDP via PayPal, Bitcoin, credit card, Perfect Money, Moneybookers, or WebMoney. DigiRDP may, at its discretion, accept other forms of payment. If the Subscriber has elected to automatically transact payments to DigiRDP, the invoice sent by email will serve as their receipt of payment.</p>
                                            <p>If the Subscriber has not elected to automatically transact payments or an automatically-transacted payment fails, the Subscriber must promptly pay any invoiced charges to DigiRDP before the invoice due date.</p>

                                            <h4>Refund Policy</h4>
                                            <p>DigiRDP's refund policy is designed to provide a better user experience and is subject to change at any time with or without notice. To process a refund for RDP plans, a valid reason must be provided by the Subscriber. DigiRDP will try to resolve the issue or issue a refund if a valid reason is provided.</p>
                                            <p>Refunds are not available in certain circumstances, including ignorance about the proper usage of RDP, network connection issues at the client's end, technical issues at the client's end, and issues with third-party software. Refunds are also not available for dedicated servers, residential RDP ISP/proxy fees, or VPS products. Invalid claims or unreasonable reasons are not eligible for a refund.</p>

                                            <h4>Term and Termination</h4>
                                            <p>This Agreement remains in effect until notice of termination is provided by either the Subscriber or DigiRDP. The Agreement and the Subscriber's access to the Service will terminate as follows: the Subscriber may terminate at the end of the current billing cycle, DigiRDP may terminate with 30 days' notice, DigiRDP may terminate with 15 days' notice if the Subscriber fails to correct a breach of this Agreement, and DigiRDP may immediately and without notice terminate if, at its sole discretion, the Subscriber violates the Acceptable Use Policy.</p>

                                            <h4>Prohibited Activities</h4>
                                            <p>The Subscriber agrees not to use the Service for any illegal or unauthorized purpose, including but not limited to hacking, brute-forcing, or mass mailing. DigiRDP strictly prohibits these activities and reserves the right to immediately terminate the Service if they are detected.</p>

                                            <h4>Unmetered Bandwidth Fair Share Policy</h4>
                                            <p>DigiRDP offers unmetered bandwidth with a fair share policy. This means that while the bandwidth is not capped, excessive use of bandwidth to the detriment of other subscribers may result in a request to reduce usage or potential service restrictions. DigiRDP strives to provide a high-quality experience for all subscribers and appreciates cooperation in maintaining fair usage of resources.</p>

                                            <h4>Modification of Service</h4>
                                            <p>DigiRDP reserves the right to modify or discontinue the Service at any time and without notice. DigiRDP will not be liable to the Subscriber or any third party for any modification or discontinuation of the Service.</p>

                                            <h4>Support</h4>
                                            <p>DigiRDP will provide support for the Service to the Subscriber via email or through a support ticket system. Support is available 24/7 and will respond to requests within a reasonable amount of time.</p>

                                            <h4>Indemnification</h4>
                                            <p>The Subscriber agrees to indemnify and hold DigiRDP and its affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of the Subscriber's use of the Service, violation of this Agreement, or violation of any rights of another.</p>

                                            <h4>Disclaimer of Warranties</h4>
                                            <p>The Service is provided on an "as is" and "as available" basis. DigiRDP makes no warranties, express or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
                                            <p>DigiRDP does not warrant that the Service will be uninterrupted or error-free, and the Subscriber agrees that from time to time DigiRDP may remove the Service for indefinite periods of time or cancel the Service at any time, without notice.</p>

                                            <h4>Limitation of Liability</h4>
                                            <p>In no event shall DigiRDP or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on DigiRDP's website, even if DigiRDP or a DigiRDP authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to the Subscriber.</p>

                                            <h4>Governing Law</h4>
                                            <p>This Agreement and any dispute arising out of or in connection with this Agreement shall be governed by and construed in accordance with the laws of the State of Wyoming, without giving effect to any principles of conflicts of law.</p>

                                            <h4>Severability</h4>
                                            <p>If any part of this Agreement is found to be invalid or unenforceable, that part will be enforced to the maximum extent possible, and the remaining provisions will remain in full force and effect.</p>

                                            <h4>Waiver</h4>
                                            <p>The failure of DigiRDP to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.</p>

                                            <h4>Entire Agreement</h4>
                                            <p>This Agreement constitutes the entire agreement between the Subscriber and DigiRDP and governs the Subscriber's use of the Service, superseding any prior agreements between the Subscriber and DigiRDP. The Subscriber may be subject to additional terms and conditions that may apply when they use affiliate services, third-party content, or third-party software.</p>

                                            <h4>Changes to this Agreement</h4>
                                            <p>DigiRDP reserves the right to make changes to this Agreement at any time and without notice. It is the Subscriber's responsibility to check this Agreement periodically for changes. The Subscriber's continued use of the Service after any changes are made constitutes their acceptance of the revised Agreement.</p>

                                            <h4>oftware Installation Policy for Shared RDP Clients</h4>
                                            <h5>Purpose :</h5>
                                            <p>The purpose of this policy is to ensure the proper and legal use of software within the shared RDP environment. All clients are expected to adhere to this policy when installing or using software on shared RDP servers.</p>
                                            <h6>Policy :</h6>
                                            <ol>
                                                <li>The installation of any software that promotes or facilitates illegal activities, such as mass mailing, brute forcing, or operating unauthorized SMTP servers, is strictly prohibited.</li>
                                                <li>Clients are not permitted to install or use software that infringes on the intellectual property rights of others.</li>
                                                <li>Clients are responsible for ensuring that any software they install on shared RDP servers is properly licensed and up-to-date.</li>
                                                <li>The shared RDP service provider must be notified before any software is installed on shared RDP servers. The service provider will review the software and ensure that it is appropriate and compliant with this policy.</li>
                                                <li>The use of VPNs is prohibited on shared RDP servers.</li>
                                                <li>Clients are not allowed to uninstall apps that are not installed by them on shared RDP servers, as it can disrupt the operation of the shared RDP servers and negatively impact the experience of other clients.</li>
                                            </ol>
                                            <p>Any client found to be in violation of this policy may have their access to the shared RDP service suspended or terminated.</p>
                                            <h6>Thank you for using our service. If you have any questions or concerns, please do not hesitate to contact us.</h6>
                                        </div>
                                    </div>
                                </div>

                                
                            </div>
                        </div>

                         {/* <!-- Start Features Area --> */}
                         <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our Plans</h2>
                                            <p>There are several plans which includes Windows RDP, Cloud VPS and Dedicated <br /> servers which will make the deployement of your application more easier</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt--60">
                                        <div className="advance-tab-button advance-tab-button-1 right-top">
                                            <ul className="nav nav-tabs tab-button-list">

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button ">
                                                        <div className="tab">
                                                            <h2 className="title">240 + </h2>
                                                            <p className="description sal-animate">Windows RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">110 +</h2>
                                                            <p className="description sal-animate">Cloud VPS Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">59 +</h2>
                                                            <p className="description sal-animate">Dedicated Server Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">114 +</h2>
                                                            <p className="description sal-animate">Private RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="view-more-button text-center mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                        <a className="btn-default color-blacked" href="/contact">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta mt-5">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Join our Experts community</h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/contact">Join DigiRDP Experts</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400"
                                            data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Blogs</span>
                                            </h4>
                                            <h2 className="title mb--60">Explore Our Insights</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <BlogSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}



                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}
            </div>
            {/* <!-- Start Pricing Area  --> */}
        

    <Footer />
    </Fragment>
  );
}
export default Terms;