import React, { Fragment, useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Testimonial from "../slider/testimonial";
import BlogSlider from "../slider/BlogSlider";
import axios from "axios";
import { useParams } from "react-router-dom";
import Promo from "../slider/Promo";
import Location from "../sales-page/Location";
const assets = '/assets';

const DedicatedPlan = () => {
    const [dedicateds, setDedicateds] = useState([]);
    const [dedicatedplans, setDedicatedplans] = useState([]);
    const { url_text } = useParams();
    const [loading, setLoading] = useState(true);
    console.log(url_text);

    useEffect(() => {
        const fetchDedicatedplan = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`https://admin.digirdp.chaipost.co.in/api/dedicated_plan/${url_text}`);
                console.log("dedicated API Response:", response.data[0]);
                console.log("dedicated plan API Response:", response.data[1]);
                setDedicateds(response.data[0]);
                setDedicatedplans(response.data[1]);
            } catch (error) {
                console.error("Error fetching dedicated plan data:", error);
            }
            finally {
                setLoading(false);
            }
        };
        if (url_text) {
            fetchDedicatedplan();
        }
    }, [url_text]);
    return (
        <Fragment>
            <Header />
            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">{dedicateds.name}</h3>
                                    <p className="description b1">{dedicateds.description}</p>
                                    <ul className="page-list">
                                        <li className="rainbow-breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="rainbow-breadcrumb-item"><a href="/">Dedicated Plan</a></li>
                                        <li className="rainbow-breadcrumb-item active">{dedicateds.name}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                {/* <!-- End Breadcarumb area  -->

            

                {/* <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">

                        <Promo />

                        {/* <!-- Start Pricing Compare Detailed  --> */}
                        <div className="rainbow-pricing-detailed-area rainbow-section-gap">
                            <div className="container container-new">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400"
                                            data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Instant and Custom Servers</span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Bare Metal Dedicated Servers </h2>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p><span className="min"><i className="fa-solid fa-clock"></i><span> Setup Instant to upto 24 hours</span></span></p>
                                </div>
                                <div className="row row--15">
                                    <div className="col-lg-12">
                                        <div className="rainbow-compare-table style-1 table-style mt-5">
                                            <div className="table-responsive-wrapper">
                                                <table className="table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th className="style-prymary">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <img src={`${assets}/images/added/cpu.png`} alt="cpu" />
                                                                    <span className="ms-2">CPU</span>
                                                                </div>
                                                            </th>
                                                            <th className="style-prymary">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <img src={`${assets}/images/added/storage.png`} alt="cpu" />
                                                                    <span className="ms-2">Storage</span>
                                                                </div>
                                                            </th>
                                                            <th className="style-prymary">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <img src={`${assets}/images/added/memory.png`} alt="cpu" />
                                                                    <span className="ms-2">Memory</span>
                                                                </div>
                                                            </th>
                                                            <th className="style-prymary">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <img src={`${assets}/images/added/transfer.png`} alt="cpu" />
                                                                    <span className="ms-2">Transfer</span>
                                                                </div>
                                                            </th>
                                                            <th className="style-prymary">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <img src={`${assets}/images/added/location.png`} alt="cpu" />
                                                                    <span className="ms-2">Location</span>
                                                                </div>
                                                            </th>
                                                            <th className="style-prymary">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <img src={`${assets}/images/added/timer.png`} alt="cpu" />
                                                                    <span className="ms-2">Setup</span>
                                                                </div>
                                                            </th>
                                                            <th className="style-prymary">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <img src={`${assets}/images/added/price.png`} alt="cpu" />
                                                                    <span className="ms-2">Price/mo</span>
                                                                </div>
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dedicatedplans.map((dedicatedplan, index) => (
                                                            <tr>
                                                                <td>{dedicatedplan.processor}</td>
                                                                <td>{dedicatedplan.drives} </td>
                                                                <td>{dedicatedplan.ram}</td>
                                                                <td>{dedicatedplan.description}</td>
                                                                <td>{dedicatedplan.location}</td>
                                                                <td><span className="min"><i className="fa-solid fa-clock"></i><span> min</span></span></td>
                                                                <td>${dedicatedplan.offer_price}  <span className="price-line">{dedicatedplan.price}</span> /mo</td>
                                                                <td><a className="btn-default color-blacked" href="/">Order Now</a></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start Features Area --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Features</span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">These features come <br /> standard in all of our plans</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start Features Area --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our Plans</h2>
                                            <p>There are several plans which includes Windows RDP, Cloud VPS and Dedicated <br /> servers which will make the deployement of your application more easier</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt--60">
                                        <div className="advance-tab-button advance-tab-button-1 right-top">
                                            <ul className="nav nav-tabs tab-button-list">

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button ">
                                                        <div className="tab">
                                                            <h2 className="title">240 + </h2>
                                                            <p className="description sal-animate">Windows RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">110 +</h2>
                                                            <p className="description sal-animate">Cloud VPS Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">59 +</h2>
                                                            <p className="description sal-animate">Dedicated Server Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">114 +</h2>
                                                            <p className="description sal-animate">Private RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="view-more-button text-center mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                        <a className="btn-default color-blacked" href="/contact">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta mt-5">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Join our Experts community</h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/contact">Join DigiRDP Experts</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400"
                                            data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Blogs</span>
                                            </h4>
                                            <h2 className="title mb--60">Explore Our Insights</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <BlogSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="view-more-button text-center mt--45 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                <a className="btn-default color-blacked" href="/blog">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}



                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}
            </div>
            {/* <!-- Start Pricing Area  --> */}


            <Location />
            <Footer />

        </Fragment>
    );
}

export default DedicatedPlan;