import React, { Fragment } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BlogSlider from "../slider/BlogSlider";
import Testimonial from "../slider/testimonial";
import Location from "./Location";
// import { Link } from 'react-router-dom';
const assets = '/assets';


function Offer() {

   

    return (
        <Fragment>
            <Header />
            
            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">Save Big with DigiRDP’s Limited-Time Promo Codes!</h3>
                                    <p className="description b1">Explore our exclusive promo codes and Coupons to save on high-performance RDP and VPS solutions. <br /> Don’t miss out on these limited-time offers grab your code and start saving today!</p>
                                    <ul className="page-list">
                                        <li className="rainbow-breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="rainbow-breadcrumb-item"><a href="/">Suppor</a></li>
                                        <li className="rainbow-breadcrumb-item active">Offers</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">
                        {/* <!-- Start Blog Area  --> */}
                        <div className="rainbow-blog-area rainbow-section-gap bg-color-1 ">
                            <div className="container container-new">
                                <div className="row row--30">
                                    <div className="col-lg-12">
                                        <div className="row mt_dec--30">
                                            <div className="col-lg-12">
                                                <div className="row row--15">
                                                    <div className="col-lg-3 col-md-6 col-12 mt--30">
                                                        <div className="rainbow-card undefined">
                                                            <div className="inner">
                                                                <div className="thumbnail">
                                                                    <a className="image" href="/blog-details"><img src={`${assets}/images/added/offer-1.jpg`} alt="Blog" />
                                                                    </a>
                                                                </div>
                                                                <div className="content">
                                                                    <ul className="rainbow-meta-list">
                                                                        <li><i className="fa fa-users"></i> 125 used</li>
                                                                        <li className="separator"></li>
                                                                        <li className="catagory-meta add"><i className="fa fa-tag"></i> Coupon</li>
                                                                    </ul>
                                                                    <h4 className="title">10% OFF</h4>
                                                                    <p className="description">Unlock Exclusive Savings with DigiRDP Promo Code</p>
                                                                    <button className="btn btn-default btn-default-new btn-icon w-100"><span>X455-17GT-OL58</span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12 mt--30">
                                                        <div className="rainbow-card undefined">
                                                            <div className="inner">
                                                                <div className="thumbnail">
                                                                    <a className="image" href="/blog-details"><img src={`${assets}/images/added/offer-2.jpg`} alt="Blog" />
                                                                    </a>
                                                                </div>
                                                                <div className="content">
                                                                    <ul className="rainbow-meta-list">
                                                                        <li><i className="fa fa-users"></i> 125 used</li>
                                                                        <li className="separator"></li>
                                                                        <li className="catagory-meta add"><i className="fa fa-tag"></i> Coupon</li>
                                                                    </ul>
                                                                    <h4 className="title">10% OFF</h4>
                                                                    <p className="description">Unlock Exclusive Savings with DigiRDP Promo Code</p>
                                                                    <button className="btn btn-default btn-default-new btn-icon w-100"><span>X455-17GT-OL58</span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12 mt--30">
                                                        <div className="rainbow-card undefined">
                                                            <div className="inner">
                                                                <div className="thumbnail">
                                                                    <a className="image" href="/blog-details"><img src={`${assets}/images/added/offer-1.jpg`} alt="Blog" />
                                                                    </a>
                                                                </div>
                                                                <div className="content">
                                                                    <ul className="rainbow-meta-list">
                                                                        <li><i className="fa fa-users"></i> 125 used</li>
                                                                        <li className="separator"></li>
                                                                        <li className="catagory-meta add"><i className="fa fa-tag"></i> Coupon</li>
                                                                    </ul>
                                                                    <h4 className="title">10% OFF</h4>
                                                                    <p className="description">Unlock Exclusive Savings with DigiRDP Promo Code</p>
                                                                    <button className="btn btn-default btn-default-new btn-icon w-100"><span>X455-17GT-OL58</span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12 mt--30">
                                                        <div className="rainbow-card undefined">
                                                            <div className="inner">
                                                                <div className="thumbnail">
                                                                    <a className="image" href="/blog-details"><img src={`${assets}/images/added/offer-2.jpg`} alt="Blog" />
                                                                    </a>
                                                                </div>
                                                                <div className="content">
                                                                    <ul className="rainbow-meta-list">
                                                                        <li><i className="fa fa-users"></i> 125 used</li>
                                                                        <li className="separator"></li>
                                                                        <li className="catagory-meta add"><i className="fa fa-tag"></i> Coupon</li>
                                                                    </ul>
                                                                    <h4 className="title">10% OFF</h4>
                                                                    <p className="description">Unlock Exclusive Savings with DigiRDP Promo Code</p>
                                                                    <button className="btn btn-default btn-default-new btn-icon w-100"><span>X455-17GT-OL58</span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12 mt--30">
                                                        <div className="rainbow-card undefined">
                                                            <div className="inner">
                                                                <div className="thumbnail">
                                                                    <a className="image" href="/blog-details"><img src={`${assets}/images/added/offer-1.jpg`} alt="Blog" />
                                                                    </a>
                                                                </div>
                                                                <div className="content">
                                                                    <ul className="rainbow-meta-list">
                                                                        <li><i className="fa fa-users"></i> 125 used</li>
                                                                        <li className="separator"></li>
                                                                        <li className="catagory-meta add"><i className="fa fa-tag"></i> Coupon</li>
                                                                    </ul>
                                                                    <h4 className="title">10% OFF</h4>
                                                                    <p className="description">Unlock Exclusive Savings with DigiRDP Promo Code</p>
                                                                    <button className="btn btn-default btn-default-new btn-icon w-100"><span>X455-17GT-OL58</span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12 mt--30">
                                                        <div className="rainbow-card undefined">
                                                            <div className="inner">
                                                                <div className="thumbnail">
                                                                    <a className="image" href="/blog-details"><img src={`${assets}/images/added/offer-2.jpg`} alt="Blog" />
                                                                    </a>
                                                                </div>
                                                                <div className="content">
                                                                    <ul className="rainbow-meta-list">
                                                                        <li><i className="fa fa-users"></i> 125 used</li>
                                                                        <li className="separator"></li>
                                                                        <li className="catagory-meta add"><i className="fa fa-tag"></i> Coupon</li>
                                                                    </ul>
                                                                    <h4 className="title">10% OFF</h4>
                                                                    <p className="description">Unlock Exclusive Savings with DigiRDP Promo Code</p>
                                                                    <button className="btn btn-default btn-default-new btn-icon w-100"><span>X455-17GT-OL58</span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12 mt--30">
                                                        <div className="rainbow-card undefined">
                                                            <div className="inner">
                                                                <div className="thumbnail">
                                                                    <a className="image" href="/blog-details"><img src={`${assets}/images/added/offer-1.jpg`} alt="Blog" />
                                                                    </a>
                                                                </div>
                                                                <div className="content">
                                                                    <ul className="rainbow-meta-list">
                                                                        <li><i className="fa fa-users"></i> 125 used</li>
                                                                        <li className="separator"></li>
                                                                        <li className="catagory-meta add"><i className="fa fa-tag"></i> Coupon</li>
                                                                    </ul>
                                                                    <h4 className="title">10% OFF</h4>
                                                                    <p className="description">Unlock Exclusive Savings with DigiRDP Promo Code</p>
                                                                    <button className="btn btn-default btn-default-new btn-icon w-100"><span>X455-17GT-OL58</span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12 mt--30">
                                                        <div className="rainbow-card undefined">
                                                            <div className="inner">
                                                                <div className="thumbnail">
                                                                    <a className="image" href="/blog-details"><img src={`${assets}/images/added/offer-2.jpg`} alt="Blog" />
                                                                    </a>
                                                                </div>
                                                                <div className="content">
                                                                    <ul className="rainbow-meta-list">
                                                                        <li><i className="fa fa-users"></i> 125 used</li>
                                                                        <li className="separator"></li>
                                                                        <li className="catagory-meta add"><i className="fa fa-tag"></i> Coupon</li>
                                                                    </ul>
                                                                    <h4 className="title">10% OFF</h4>
                                                                    <p className="description">Unlock Exclusive Savings with DigiRDP Promo Code</p>
                                                                    <button className="btn btn-default btn-default-new btn-icon w-100"><span>X455-17GT-OL58</span></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* <!-- Start Features Area --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our Plans</h2>
                                            <p>There are several plans which includes Windows RDP, Cloud VPS and Dedicated <br /> servers which will make the deployement of your application more easier</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt--60">
                                        <div className="advance-tab-button advance-tab-button-1 right-top">
                                            <ul className="nav nav-tabs tab-button-list">

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button ">
                                                        <div className="tab">
                                                            <h2 className="title">240 + </h2>
                                                            <p className="description sal-animate">Windows RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">110 +</h2>
                                                            <p className="description sal-animate">Cloud VPS Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">59 +</h2>
                                                            <p className="description sal-animate">Dedicated Server Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">114 +</h2>
                                                            <p className="description sal-animate">Private RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="view-more-button text-center mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                        <a className="btn-default color-blacked" href="/contact">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta mt-5">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Join our Experts community</h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/contact">Join DigiRDP Experts</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400"
                                            data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Blogs</span>
                                            </h4>
                                            <h2 className="title mb--60">Explore Our Insights</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <BlogSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="view-more-button text-center mt--45 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                <a className="btn-default color-blacked" href="/blog">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}

                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}
            </div>
            {/* <!-- Start Pricing Area  --> */}

            <Location />

            <Footer />
        </Fragment>
    );
}

export default Offer;

