import React, { Fragment } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CountDown from "./CountDown";
import Location from "../sales-page/Location";
const assets = '/assets';

const BlackFriday = () => {
    

    return (
        <Fragment>
            <Header />
            {/* main top */}

            <div className="slider-area slider-style-1 variation-default slider-bg-image  slider-bg-shape sales-banner" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="inner text-center mt--140">
                                <h4 className="subtitle">
                                    <span ><span>🔥</span> <span className="theme-gradient">What’s on Offer?</span>  </span>
                                </h4>
                                <h1 className="title display-one"><span class="highlight-1">Black Friday</span> </h1>
                                <div id="neon">Sales</div>   
                                <p className="description p-0">This Black Friday, experience blazing-fast RDP and VPS solutions at unbeatable prices. ⏳ Hurry! Offer valid for a limited time only. Don’t miss out on the best cloud deals of the year!</p>
                                <a className="btn-default @@btnclassName" href="/contact">Contact Us Now</a>
                                <div className="inner-shape">
                                    <img src={`${assets}/images/bg/icon-shape/icon-shape-one.png`} alt="Icon Shape" className="iconshape iconshape-one" />
                                    <img src={`${assets}/images/bg/icon-shape/icon-shape-two.png`} alt="Icon Shape" className="iconshape iconshape-two" />
                                    <img src={`${assets}/images/bg/icon-shape/icon-shape-three.png`} alt="Icon Shape" className="iconshape iconshape-three" />
                                    <img src={`${assets}/images/bg/icon-shape/icon-shape-four.png`} alt="Icon Shape" className="iconshape iconshape-four" />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-shape">  
                    <img className="bg-shape-one" src={`${assets}/images/bg/bg-shape-four.png`} alt="Bg Shape" />
                    <img className="bg-shape-two" src={`${assets}/images/bg/bg-shape-five.png`} alt="Bg Shape" />
                </div>
            </div>

            <CountDown />

            {/* <!-- Start CTA Style-one Area  --> */}
            <div className="rainbow-rn-cta mt-5 mb-5">
                <div className="container">
                    <div className="row row--0 align-items-center content-wrapper">
                        <div className="col-lg-8">
                            <div className="inner">
                                <div className="content text-left">
                                    <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                        data-sal-delay="200">Join our Experts community</h4>
                                    <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="right-content">
                                <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                    data-sal-duration="400" data-sal-delay="400">
                                    <div className="team-image">
                                        <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                    </div>
                                    <a className="btn-default" href="/contact">Join DigiRDP Experts</a>
                                </div>
                            </div>
                        </div>
                        <div className="bg-shape">
                            <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                        </div>
                    </div>
                </div>
            </div>

           
           <Location />
            <Footer />

        </Fragment>
    );
}

export default BlackFriday;
