import React, { Fragment, useState, useEffect } from "react";
// import { FlipDown } from "flipdown"; // Named import



const CountDown = () => {
    
    const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  // Initialize the countdown timer and Kofi widget
  useEffect(() => {
    const countDownTimer = () => {
      const now = new Date();
      const daysInThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
      const daysLeft = daysInThisMonth - now.getDate();
      const hoursLeft = 23 - now.getHours();
      const minutesLeft = 59 - now.getMinutes();
      const secondsLeft = 59 - now.getSeconds();

      setDays(daysLeft);
      setHours(hoursLeft);
      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    };

    // Set an interval to update the countdown every second
    const timer = setInterval(countDownTimer, 1000);

    // Initialize Kofi widget (only once)
    if (window.KofiWidgetOverlay) {
      window.KofiWidgetOverlay.draw('vehementjane', {
        type: 'floating-chat',
        'floating-chat.donateButton.text': 'Support me',
        'floating-chat.donateButton.background-color': '#794bc4',
        'floating-chat.donateButton.text-color': '#fff'
      });
    }

    // Start the countdown immediately
    countDownTimer();

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []); // Empty dependency array ensures this runs only once on mount

    return (
        <Fragment>
          
        <div class="rainbow-cta-area rainbow-section-gap rainbow-section-gapBottom-big">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="aiwave-cta">
                            <div class="inner-new">

                                
                                
                                <div id="counter">
                                    <h2 class="box" id="days">{days}<br /> <span className="small">days</span></h2>
                                    <h2 class="box" id="hours">{hours}<br /> <span className="small">hours</span></h2>
                                    <h2 class="box" id="minutes">{minutes}<br /> <span className="small">mins</span></h2>
                                    <h2 class="box" id="seconds">{seconds}<br /> <span className="small">secs</span></h2>
                                </div>

                                <div class="bg-shape-one" style={{ position: "absolute" , zIndex: "-1" }}>
                                    <img src="assets/images/cta-img/bg-shape.png" alt="Bg shape" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </Fragment>
    );
}

export default CountDown;
