import React, { Fragment } from "react";
const assets = '/assets';

const Promo = () => {
    return (
        <Fragment>

            {/* <!-- Start Blog Area  --> */}
            <div className="rainbow-blog-area ">
                <div className="container-fluid">
                    <div className="col-lg-12">
                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                            <h4 className="subtitle">
                                <span className="theme-gradient">Our Offers</span>
                            </h4>
                        </div>
                    </div>
                    <div class="burger-slider">
                        <div class="slider-wrapper row">
                            <div className="slide col-lg-3 col-md-6 col-sm-12">
                                <div className="img-container">
                                    <img src={`${assets}/images/added/cop-1.png`} alt="" className="burger-image" />
                                    <div className="burger-info">
                                        <div className="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div className="burger-description">#SAVE20</div>
                                        <button className="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div className="slide col-lg-3 col-md-6 col-sm-12">
                                <div className="img-container">
                                    <img src={`${assets}/images/added/cop-2.png`} alt="" className="burger-image" />
                                    <div className="burger-info">
                                        <div className="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div className="burger-description">#SAVE20</div>
                                        <button className="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div className="slide col-lg-3 col-md-6 col-sm-12">
                                <div className="img-container">
                                    <img src={`${assets}/images/added/cop-1.png`} alt="" className="burger-image" />
                                    <div className="burger-info">
                                        <div className="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div className="burger-description">#SAVE20</div>
                                        <button className="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div className="slide col-lg-3 col-md-6 col-sm-12">
                                <div className="img-container">
                                    <img src={`${assets}/images/added/cop-2.png`} alt="" className="burger-image" />
                                    <div className="burger-info">
                                        <div className="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div className="burger-description">#SAVE20</div>
                                        <button className="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-1.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-2.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-1.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-2.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-1.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-2.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-1.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-2.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-1.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-2.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-1.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-2.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-1.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20
                                        </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                            <div class="slide col-lg-3 col-md-6 col-sm-12">
                                <div class="img-container">
                                    <img src={`${assets}/images/added/cop-2.png`} alt="" class="burger-image" />
                                    <div class="burger-info">
                                        <div class="burger-title"> Save 20% on All DigiRDP Services!</div>
                                        <div class="burger-description">#SAVE20 </div>
                                        <button class="add-to-cart">+ Copy</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Promo;
