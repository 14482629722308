import React, { Fragment, useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BlogSlider from "../slider/BlogSlider";
import Testimonial from "../slider/testimonial";
import axios from "axios";
import Promo from "../slider/Promo";
import Location from "../sales-page/Location";
const assets = '/assets';

function KnowledgeBase() {
    const [faqs, setFaqs] = useState([]);
    const [faqs_category, setFaqs_category] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFaqs = async () => {
            setLoading(true); // Set loading to true before fetching
            try {
                const response = await axios.get("https://admin.digirdp.chaipost.co.in/api/faqs");
                setFaqs(response.data[0]);
                setFaqs_category(response.data[1]);
            } catch (error) {
                console.error("Error fetching FAQ data:", error);
            } finally {
                setLoading(false); // Ensure loading is set to false after fetching
            }
        };

        fetchFaqs();
    }, []);

    const [activeCategory, setActiveCategory] = useState(null);

    const [activeFAQ, setActiveFAQ] = useState({});

    const toggleFAQ = (categoryId, faqId) => {
        setActiveFAQ((prevState) => ({
            ...prevState,
            [categoryId]: prevState[categoryId] === faqId ? null : faqId,
        }));
    };

    const toggleCategory = (categoryId) => {
        setActiveCategory((prev) => (prev === categoryId ? null : categoryId));
    };

    console.log(faqs);
    return (
        <Fragment>
            <Header />
            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">Knowledge Base</h3>
                                    <p className="description b1">
                                        Here are some answers to some commonly asked questions
                                    </p>
                                    <ul className="page-list">
                                        <li className="rainbow-breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="rainbow-breadcrumb-item">
                                            <a href="/">Support</a>
                                        </li>
                                        <li className="rainbow-breadcrumb-item active">
                                            Knowledge base
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">

                        <Promo />

                        {/* <!-- Start RDP FAQs Area --> */}
                        <div className="rainbow-accordion-area rainbow-section-gap">
                            <div className="container">
                                {faqs_category.map((category) => (
                                    <div key={category.category_id} className="row">
                                        <div className="col-lg-12">
                                            <div
                                                className="section-title text-center"
                                                data-sal-duration="400"
                                                data-sal-delay="150"
                                            >
                                                <h4 className="subtitle">
                                                    <span className="theme-gradient"></span>
                                                </h4>
                                                <h2 className="title w-600 mb--20">
                                                    {category.category_name}
                                                </h2>
                                                <p>Let's see if we can help.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-xl-12 col-12 has-show-more">
                                            <div
                                                className="rainbow-accordion-style rainbow-accordion-02 has-show-more-inner-content has-show-more-inner-content-new large-height"
                                                id={`accordion-${category.category_id}`}
                                            >
                                                <div className="accordion" id={`accordionExample-${category.category_id}`}>
                                                    {faqs
                                                        .filter((faq) => faq.category_id === category.category_id)
                                                        .map((faq, index) => {
                                                            const isActive = activeFAQ[category.category_id] === faq.id;
                                                            const isFirst = index === 0; // Check if it's the first FAQ in the category

                                                            return (
                                                                <div className="accordion-item card" key={faq.id}>
                                                                    <h2
                                                                        className={`accordion-header card-header ${isActive ? "text-purple" : ""}`}
                                                                        id={`heading-${faq.id}`}
                                                                    >
                                                                        <button
                                                                            className={`accordion-button ${isActive ? "" : "collapsed"}`}
                                                                            type="button"
                                                                            onClick={() => toggleFAQ(category.category_id, faq.id)}
                                                                            aria-expanded={isFirst || isActive ? "true" : "false"}
                                                                            aria-controls={`collapse-${faq.id}`}
                                                                        >
                                                                            {faq.question}
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={`collapse-${faq.id}`}
                                                                        className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
                                                                        aria-labelledby={`heading-${faq.id}`}
                                                                        data-bs-parent={`#accordion-${category.category_id}`}
                                                                    >
                                                                        <div className="accordion-body card-body">
                                                                            {faq.answer}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                            <div className="rbt-show-more-btn">Show More</div>
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>

                        {/* <!-- Start Cloud VPS FAQs Area --> */}
                        {/* <div className="rainbow-accordion-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Cloud VPS FAQs</h2>
                                            <p>Let's see if we can help.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-12 col-12 has-show-more">
                                        <div className="rainbow-accordion-style rainbow-accordion-02 has-show-more-inner-content large-height">
                                            <div className="accordion accordion " id="accordionExamplec">
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading7">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="true" aria-controls="collapse7">What is the purpose of a VPS used for?</button>
                                                    </h2>
                                                    <div id="collapse7" className="accordion-collapse collapse show" aria-labelledby="heading7" data-bs-parent="#accordionExamplec">
                                                        <div className="accordion-body card-body">A VPS allows you to host sites (website, e-commerce, content, and media) and software applications (e.g. CRM, Adobe, WordPress and other software collaborative solutions) with ease. Unlike shared hosting, a VPS allows multiple applications to be isolated from one another within the same server. Even though the VPS shares the physical resources (infrastructure) with other users, its computing resources are dedicated to you.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading8">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">What is VPS?</button>
                                                    </h2>
                                                    <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExamplec">
                                                        <div className="accordion-body card-body">A Virtual Private Server (VPS) is an independent, virtual partition on a physical server (think of a server with different “containers” within it) which is sold as a service by an Internet hosting provider. A VPS runs its own copy of an operating system, and customers have administrative level access to that operating system so that they can install almost any software applications that run on that OS.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading9">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">how many IP will I get with VPS?</button>
                                                    </h2>
                                                    <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionExamplec">
                                                        <div className="accordion-body card-body">You will get IP Address 1 Dedicated IPV4 with your VPS server.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading10">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">What Is The Setup Time Of Our VPS Servers ?</button>
                                                    </h2>
                                                    <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionExamplec">
                                                        <div className="accordion-body card-body">You will get your purchased VPS within 4 hours after making payment, but in some unexpected cases it might take upto 12Hours, not more than that.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading11">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">Who manages my VPS?</button>
                                                    </h2>
                                                    <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionExamplec">
                                                        <div className="accordion-body card-body">All our VPS plans come completely unmanaged. You get a default install of the operating system of your choice and any other software purchased during checkout. You get full root / administrator access to your virtual server and it is your responsibility to ensure the security and reliability of your server. We are responsible for the uptime of your VPS container only, not for the uptime of your operating system or the services that you run on it. If you have difficulties in managing your VPS you can request support from our dedicated system administration team.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading12">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">Which type of storage do you provide Hybrid or Pure SSD?</button>
                                                    </h2>
                                                    <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordionExamplec">
                                                        <div className="accordion-body card-body">Our VPS servers come equipped with Pure SSD storage. This means that the entire storage capacity of the VPS server is made up of solid state drives (SSDs), which offer faster and more reliable performance than traditional hard disk drives (HDDs). SSDs use non-volatile memory chips to store data, which means that they do not require any mechanical parts and can access data more quickly and efficiently. This makes Pure SSD storage ideal for applications that require high performance and low latency, such as databases, online gaming, or video streaming.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rbt-show-more-btn">Show More</div>
                                    </div>
                                </div>
                            </div>
                        </div>  */}

                        {/* <!-- Start Web Hosting FAQs Area --> */}
                        {/* <div className="rainbow-accordion-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Web Hosting FAQs</h2>
                                            <p>Let's see if we can help.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-12 col-12 ">
                                        <div className="rainbow-accordion-style rainbow-accordion-02 ">
                                            <div className="accordion accordion " id="accordionExamplec">
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading13">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="true" aria-controls="collapse13">Do you know we are best? how?</button>
                                                    </h2>
                                                    <div id="collapse13" className="accordion-collapse collapse show" aria-labelledby="heading13" data-bs-parent="#accordionExamplec">
                                                        <div className="accordion-body card-body">Yes we are the best because we have been providing RDP services since last few years and we have 99.99% Uptime with quality RDP. We have a variety of RDP plans for different purposes and our support is available 24/7. you can also check about us on trustpilot. we have a lot of clients from throughout the world who are happy with our services and appreciate our work.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <!-- Start Dedicated Server FAQs Area --> */}
                        {/* <div className="rainbow-accordion-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Dedicated Server FAQs</h2>
                                            <p>Let's see if we can help.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-12 col-12 has-show-more">
                                        <div className="rainbow-accordion-style rainbow-accordion-02 has-show-more-inner-content has-show-more-inner-content-new large-height">
                                            <div className="accordion accordion " id="accordionExampled">
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading14">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="true" aria-controls="collapse14">What's the bandwidth limit for dedicated server?</button>
                                                    </h2>
                                                    <div id="collapse14" className="accordion-collapse collapse show" aria-labelledby="heading14" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">We offer unmetered bandwidth with our dedicated server plans, but some of our plans has less bandwidth limit.. You can check bandwidth limit written on pricing table before making your purchase..</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading15">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">What is a Dedicated Server?</button>
                                                    </h2>
                                                    <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">A dedicated server is a type of hosting service where the user leases an entire physical server and its resources for their exclusive use. This means that the server is not shared with any other users or clients, and the user has full control over the server and its hardware. With a dedicated server, the user can choose the hardware, software, operating system, and other settings that best suit their needs and requirements. Compared to other types of hosting, such as VPS or shared hosting, a dedicated server offers several advantages, such as high performance, security, email stability, uptime, and full administrator control. Because a dedicated server is not shared with anyone else, it can provide higher levels of performance and reliability, as well as more flexible and customizable options. However, dedicated servers are typically more expensive than other types of hosting, as they require a larger investment in hardware and resources. They are also more complex and require more technical expertise to manage and maintain, which can make them less suitable for users with limited experience or knowledge. Overall, a dedicated server is a valuable and powerful tool for businesses and organizations that need high-performance, secure, and reliable hosting services. It provides full control and flexibility over the server and its resources, enabling users to tailor their hosting solutions to their specific needs and requirements.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading16">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">What is the Setup time for a Dedicated server?</button>
                                                    </h2>
                                                    <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="heading16" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">The setup time for a dedicated server depends on a number of factors, such as the type of server, the hardware configuration, and the software and operating system requirements. Typically, dedicated servers are processed within 15 minutes, and the full setup and configuration process can take anywhere from 12 hours to 3 days. During this time, the dedicated server provider will install and configure the necessary hardware and software, as well as perform any necessary testing and verification to ensure that the server is ready for use. The user can also request specific configurations or settings that may require additional time and effort to implement. Once the setup process is complete, the dedicated server provider will typically provide the user with access to the server and its resources, as well as any necessary documentation and support materials. The user can then begin using the server for their intended purposes, such as hosting a website, running applications, or storing and managing data. Overall, the setup time for a dedicated server can vary depending on the specific requirements and configurations, but is typically completed within a few hours to a few days.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading17">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">What are the features of servers?</button>
                                                    </h2>
                                                    <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">The server is the core of any data center. It is responsible for storing and providing data to clients. The features of servers are vast and include: Processor: The processor is the brain of a computer, it processes instructions and tells the other components what to do. Memory: Memory is where data can be stored temporarily or permanently. It can also be used as a workspace for processing instructions. Storage: Storage is where data can be stored on a long-term basis like hard drives, flash drives, CDs or DVDs etc. Networking: Networking allows computers to share resources with one another and talk to each other over a network (e.g., Ethernet). Input/Output Devices: Input/output devices allow input from outside sources like mouse, keyboard, touch screen.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading18">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">What is the difference between a server and a PC?</button>
                                                    </h2>
                                                    <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="heading18" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">A server is a computer that manages network traffic, storage, and other hardware resources. It also provides information to the users. A PC is a personal computer that can be used for running applications and accessing data stored on the device. The main difference between a server and a PC is that servers are more powerful than PCs because they have more storage, memory, processing power, and bandwidth.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading19">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">What are some ways that the server can be overloaded?</button>
                                                    </h2>
                                                    <div id="collapse19" className="accordion-collapse collapse" aria-labelledby="heading19" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">Server overload can happen in many ways. One of the most common reasons is because of a sudden increase in traffic. This could be due to a product launch or an event that is trending on social media. The server will not be able to handle the increased load, which will result in errors and downtime for your website or app. Another way that servers can be overloaded is if too many people are accessing it at once. This could be because somebody posted your link on social media and it went viral, or because you're hosting an event and people want to know more about it. The server will struggle to process all the requests, which can result in errors and downtime for your website or app.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading20">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">Why is it important to have good servers?  </button>
                                                    </h2>
                                                    <div id="collapse20" className="accordion-collapse collapse" aria-labelledby="heading20" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">Having good servers is important for a number of reasons: Improved performance and reliability: Good servers are typically equipped with high-performance hardware and software, which can provide faster and more reliable performance for applications and workloads. This is especially important for businesses and organizations that rely on their servers for critical operations and services, such as hosting websites, running databases, or providing online services. Enhanced security and protection: Good servers come with advanced security features and technologies, such as firewalls, intrusion detection/prevention, and encryption, which can help to protect the server and its data from potential threats and vulnerabilities. This is important for maintaining the confidentiality and integrity of sensitive information, and for preventing data breaches or attacks. Increased flexibility and scalability: Good servers are designed to be flexible and scalable, which means that they can be easily configured and customized to meet the changing needs and requirements of the user. This is important for businesses and organizations that need to adapt to changing market conditions or workloads, and for users who need to expand or upgrade their server capabilities over time. Enhanced user experience and satisfaction: Good servers can provide a better user experience and higher levels of satisfaction for users who access and use the server. This is because good servers can provide faster and more reliable performance, as well as more flexible and customizable options, which can help to improve the user's productivity and efficiency. Overall, having good servers is important for achieving high performance, security, flexibility, and user satisfaction, which are critical for businesses and organizations that rely on servers for their operations and services.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading21">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">What are the different types of servers?</button>
                                                    </h2>
                                                    <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="heading21" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">There are several different types of servers, including: Web servers: Web servers are designed to host and serve web pages and content over the internet. They use the HTTP protocol to transmit data and information to users who access the web pages using a web browser. Application servers: Application servers are designed to host and run applications that provide specific services or functions, such as databases, messaging systems, or e-commerce platforms. They typically provide a runtime environment and a set of APIs that enable applications to access and use the server's resources and capabilities. File servers: File servers are designed to store, share, and manage files and data over a network. They provide a central location for users to access and share files, and can support a wide range of file types and formats. Database servers: Database servers are designed to store, manage, and retrieve data from databases. They provide a structured and organized environment for storing and accessing data, and support a wide range of data models and query languages. Mail servers: Mail servers are designed to send and receive electronic messages, such as emails, over a network. They provide a central location for managing and routing messages, and can support a wide range of protocols and standards. Overall, the different types of servers are designed to meet specific needs and requirements, such as hosting web pages, running applications, storing and sharing files, managing databases, or sending and receiving messages.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading22">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">What are the benefits of an anti-DDoS infrastructure?</button>
                                                    </h2>
                                                    <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">A DDoS attack is a type of cyber-attack that overloads the target server with traffic, causing a denial of service. A DDoS attack is considered to be one of the most severe types of cyber-attacks because it can bring down even the most robust and powerful servers. The following are some benefits of an anti-DDoS infrastructure: 1) It prevents downtime for websites and other services. 2) It provides protection against malware and other threats. 3) It offers protection against data theft, data manipulation, and confidential information leakage.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading23">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">What are some disadvantages of hosting your own server?</button>
                                                    </h2>
                                                    <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="heading23" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">There are several disadvantages of hosting your own server, including: High cost and complexity: Hosting your own server can be expensive and complex, as it requires a significant investment in hardware, software, and infrastructure, as well as technical expertise to manage and maintain the server. This can make it difficult or impractical for businesses or organizations with limited budgets or resources. Limited scalability and flexibility: Hosting your own server can limit your ability to scale and adapt to changing needs and requirements, as it may be difficult or expensive to add or upgrade hardware, software, or other components. This can make it challenging to support growing or evolving workloads or applications. Security and compliance risks: Hosting your own server can expose your business or organization to security and compliance risks, as you are responsible for protecting the server and its data from potential threats and vulnerabilities. This can require significant effort and resources to implement and maintain robust security measures, which may not be feasible for all organizations. Poor performance and reliability: Hosting your own server can result in poor performance and reliability, as you are responsible for ensuring that the server and its components are functioning properly and are able to handle the workloads and applications that you are hosting. This can require regular monitoring and maintenance, which can be time-consuming and costly.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading24">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">What is the difference between a Dedicated Server and a VPS?</button>
                                                    </h2>
                                                    <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="heading24" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">A Dedicated Server is a standalone server that is used by a single customer, who has complete control over the resources. A VPS, on the other hand, is a virtual server that provides resources to multiple customers. A dedicated server will be more expensive than a VPS.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading25">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse25" aria-expanded="false" aria-controls="collapse25">What are the benefits of a Dedicated Server?</button>
                                                    </h2>
                                                    <div id="collapse25" className="accordion-collapse collapse" aria-labelledby="heading25" data-bs-parent="#accordionExampled">
                                                        <div className="accordion-body card-body">A dedicated server is a server that is reserved for one customer. A dedicated server has many benefits. Here are the top 3: 1) Dedicated servers provide better performance for your website. 2) They offer more control over the security of your site. 3) They are easier to maintain and manage because they are not shared with any other customer.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rbt-show-more-btn">Show More</div>
                                    </div>
                                </div>
                            </div>
                        </div>  */}

                        {/* <!-- Start Payments FAQs Area --> */}
                        {/* <div className="rainbow-accordion-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Payments FAQs</h2>
                                            <p>Let's see if we can help.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-12 col-12 ">
                                        <div className="rainbow-accordion-style rainbow-accordion-02 ">
                                            <div className="accordion accordion " id="accordionExamplec">
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading26">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse26" aria-expanded="true" aria-controls="collapse26">Which payment mode do you accept?</button>
                                                    </h2>
                                                    <div id="collapse26" className="accordion-collapse collapse show" aria-labelledby="heading26" data-bs-parent="#accordionExamplec">
                                                        <div className="accordion-body card-body">We accept multiple payment modes like Perfect Money, Bitcoin, Bank Transfer, PayTM, Indian Debit Card / Credit Card, LTC/ETH, WebMoney.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  */}

                        {/* <!-- Start Others FAQs Area --> */}
                        {/* <div className="rainbow-accordion-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Others FAQs</h2>
                                            <p>Let's see if we can help.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-12 col-12 has-show-more">
                                        <div className="rainbow-accordion-style rainbow-accordion-02 has-show-more-inner-content large-height">
                                            <div className="accordion accordion " id="accordionExamplee">
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading27">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse27" aria-expanded="true" aria-controls="collapse27">Which payment mode do you accept?</button>
                                                    </h2>
                                                    <div id="collapse27" className="accordion-collapse collapse show" aria-labelledby="heading27" data-bs-parent="#accordionExamplee">
                                                        <div className="accordion-body card-body">We accept multiple payment modes like Paypal, PerfectMoney, Bitcoin, Bank Transfer, PayTM, Indian Debit Card / Credit Card.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading28">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse28" aria-expanded="false" aria-controls="collapse28">Do you offer any demo or Trial for your service?</button>
                                                    </h2>
                                                    <div id="collapse28" className="accordion-collapse collapse" aria-labelledby="heading28" data-bs-parent="#accordionExamplee">
                                                        <div className="accordion-body card-body">No, we do not provide demo or Trial because our plans are already cheap.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading29">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse29" aria-expanded="false" aria-controls="collapse29">Will I get Admin access on Cheap Shared RDP?</button>
                                                    </h2>
                                                    <div id="collapse29" className="accordion-collapse collapse" aria-labelledby="heading29" data-bs-parent="#accordionExamplee">
                                                        <div className="accordion-body card-body">No, Cheap Shared RDP does not comes with admin access.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading30">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse30" aria-expanded="false" aria-controls="collapse30">How to connect to RDP?</button>
                                                    </h2>
                                                    <div id="collapse30" className="accordion-collapse collapse" aria-labelledby="heading30" data-bs-parent="#accordionExamplee">
                                                        <div className="accordion-body card-body">In order to connect to RDP you need Server credentials (IP Address, Username, & Password) which you will receive in the product activation email. You can find detailed tutorial in our knowledgebase.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item card">
                                                    <h2 className="accordion-header card-header" id="heading31">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse31" aria-expanded="false" aria-controls="collapse31">Is port 25 is open on your RDP?</button>
                                                    </h2>
                                                    <div id="collapse31" className="accordion-collapse collapse" aria-labelledby="heading31" data-bs-parent="#accordionExamplee">
                                                        <div className="accordion-body card-body">You need to make support ticket and mention usage of port 25. Our team will investigate and enable it.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rbt-show-more-btn">Show More</div>
                                    </div>
                                </div>
                            </div>
                        </div>  */}

                        {/* <!-- Start Features Area --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our Plans</h2>
                                            <p>There are several plans which includes Windows RDP, Cloud VPS and Dedicated <br /> servers which will make the deployement of your application more easier</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt--60">
                                        <div className="advance-tab-button advance-tab-button-1 right-top">
                                            <ul className="nav nav-tabs tab-button-list">

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button ">
                                                        <div className="tab">
                                                            <h2 className="title">240 + </h2>
                                                            <p className="description sal-animate">Windows RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">110 +</h2>
                                                            <p className="description sal-animate">Cloud VPS Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">59 +</h2>
                                                            <p className="description sal-animate">Dedicated Server Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">114 +</h2>
                                                            <p className="description sal-animate">Private RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="view-more-button text-center mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                        <a className="btn-default color-blacked" href="/contact">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta mt-5">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Join our Experts community</h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/contact">Join DigiRDP Experts</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}
                        
                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Blogs</span>
                                            </h4>
                                            <h2 className="title mb--60">Explore Our Insights</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <BlogSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="view-more-button text-center mt--45 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                <a className="btn-default color-blacked" href="/blog">
                                    View Plans{" "}<i className="fa-sharp fa-light fa-arrow-right ml--5"></i>
                                </a>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}
                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}
            </div>
            {/* <!-- Start Pricing Area  --> */}

            <Location />

            <Footer />
        </Fragment>
    );
}

export default KnowledgeBase;
