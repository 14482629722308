import React, { Fragment } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Testimonial from "../slider/testimonial";
import BlogSlider from "../slider/BlogSlider";
const assets = '/assets';

const NetherlandsCloud = () => {

    return (
        <Fragment>
            <Header />


            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">Netherlands Cloud VPS</h3>
                                    <p className="description b1">Discover top-quality Netherlands Cloud VPS SSD hosting at unbeatable prices. <br /> Secure, reliable, and blazing-fast Netherlands SSD hosting servers available now!</p>
                                    <ul className="page-list">
                                        <li className="rainbow-breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="rainbow-breadcrumb-item"><a href="/">Cloud VPS</a></li>
                                        <li className="rainbow-breadcrumb-item active">Netherlands Cloud VPS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">

                        {/* <!-- Pricing Part --> */}
                        <div className="wrapper rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Pricing</span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our product</h2>
                                            <p className="description b1">Priced to fit your specific needs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row--15">

                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">Netherlands Cloud VPS #1</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$5  </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$8.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Dual Intel/AMD Ryzen Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 1 vCores CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Linux Operating System
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 1Gb  RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 1TB Monthly
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 15Gb NVMe
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Root Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Free Backup
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location- Netherlands
                                                                </li>
                                                                
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge">Hot</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave active">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-two">Netherlands Cloud VPS #2</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$8   </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$11.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Dual Intel/AMD Ryzen Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 1 vCores CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Linux Operating System
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 2Gb  RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 2TB Monthly
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 20Gb NVMe
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Root Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Free Backup
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location- Netherlands
                                                                </li>
                                                                
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }}>New</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-waveform-lines"></i>
                                                        </div>
                                                        <h4 className="title color-var-three">Netherlands Cloud VPS #3</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$12 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$14.99 </span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Dual Intel/AMD Ryzen Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 2 vCores CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>    
                                                                    <i className="fa-regular fa-circle-check"></i> Linux Operating System 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 3Gb  RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 2TB Monthly
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 30Gb  NVMe
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Root Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Free Backup
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location- Netherlands
                                                                </li>
                                                                
                                                                
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge">Hot</div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">Netherlands Cloud VPS #4</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$15 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$19.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Dual Intel/AMD Ryzen Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 3 vCores CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>    
                                                                    <i className="fa-regular fa-circle-check"></i> Linux Operating System 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 4Gb  RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 3TB Monthly
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 40Gb  NVMe
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Root Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Free Backup
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location- Netherlands
                                                                </li>
                                                                
                                                                
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge">Hot</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">Netherlands Cloud VPS #5</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$20 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$26.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Dual Intel/AMD Ryzen Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 4 vCores CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>    
                                                                    <i className="fa-regular fa-circle-check"></i> Linux Operating System 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 6Gb  RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 10TB Monthly
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 60Gb  NVMe
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Root Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Free Backup
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location- Netherlands
                                                                </li>
                                                                
                                                                
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }}>New</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">Netherlands Cloud VPS #6</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$26 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$45.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Dual Intel/AMD Ryzen Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 4 vCores CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>    
                                                                    <i className="fa-regular fa-circle-check"></i> Linux Operating System 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 8Gb  RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 10TB Monthly
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 80Gb  NVMe
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Root Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Free Backup
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location- Netherlands
                                                                </li>
                                                                
                                                                
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }}>New</div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">Netherlands Cloud VPS #7</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$35 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$45.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Dual Intel/AMD Ryzen Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 4 vCores CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>    
                                                                    <i className="fa-regular fa-circle-check"></i> Linux Operating System 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 12Gb  RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 10TB Monthly
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 160Gb  NVMe
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Root Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Free Backup
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location- Netherlands
                                                                </li>
                                                                
                                                                
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }}>New</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">Netherlands Cloud VPS #8</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$44 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$45.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Dual Intel/AMD Ryzen Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 4 vCores CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>    
                                                                    <i className="fa-regular fa-circle-check"></i> Linux Operating System 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 16Gb  RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 10TB Monthly
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 200Gb  NVMe
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Root Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Free Backup
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location- Netherlands
                                                                </li>
                                                                
                                                                
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }}>New</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt--30">
                                        <div className="rainbow-pricing style-aiwave ">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-top">
                                                    <div className="pricing-header">
                                                        <div className="icon">
                                                            <i className="fa-sharp fa-regular fa-flower"></i>
                                                        </div>
                                                        <h4 className="title color-var-one">Netherlands Cloud VPS #9</h4>
                                                        <div className="pricing">
                                                            <span className="price-text">$75 </span>
                                                            <span className="text d-flex"><span
                                                                style={{ textDecoration: "line-through" }}>$45.99</span>/Per
                                                                Month</span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing-body">
                                                        <div className="features-section has-show-more">
                                                            <h6>Features</h6>
                                                            <ul className="list-style--1 has-show-more-inner-content">
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Single user
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Dual Intel/AMD Ryzen Processor
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 4 vCores CPU
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs
                                                                </li>
                                                                <li>    
                                                                    <i className="fa-regular fa-circle-check"></i> Linux Operating System 
                                                                </li>
                                                                <li>
                                                                    <i className="fa-regular fa-circle-check"></i> 32Gb  RAM
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Bandwidth 10TB Monthly
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> IP Address 1 IPV4
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 250Gb  NVMe
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> 99.99% uptime
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Full Root Access
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Dedicated Control panel
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Free Backup
                                                                </li>
                                                                <li>
                                                                    <i className="fa-sharp fa-regular fa-minus-circle"></i> Location- Netherlands
                                                                </li>
                                                                
                                                                
                                                            </ul>
                                                            <div className="rbt-show-more-btn">Show More</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <a className="btn-default" href="/">Buy Now</a>
                                                </div>
                                            </div>
                                            <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }}>New</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start Features Area --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Features</span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">These features come <br /> standard in all of our plans</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start Features Area --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our Plans</h2>
                                            <p>There are several plans which includes Windows RDP, Cloud VPS and Dedicated <br /> servers which will make the deployement of your application more easier</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt--60">
                                        <div className="advance-tab-button advance-tab-button-1 right-top">
                                            <ul className="nav nav-tabs tab-button-list">

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button ">
                                                        <div className="tab">
                                                            <h2 className="title">240 + </h2>
                                                            <p className="description sal-animate">Windows RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">110 +</h2>
                                                            <p className="description sal-animate">Cloud VPS Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">59 +</h2>
                                                            <p className="description sal-animate">Dedicated Server Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">114 +</h2>
                                                            <p className="description sal-animate">Private RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="view-more-button text-center mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                        <a className="btn-default color-blacked" href="/contact">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Join our Experts community</h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/contact">Join Now</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400"
                                            data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Blogs</span>
                                            </h4>
                                            <h2 className="title mb--60">Explore Our Insights</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <BlogSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="view-more-button text-center mt--45 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                <a className="btn-default color-blacked" href="/blog">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}



                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}
            </div>
            {/* <!-- Start Pricing Area  --> */}



            <Footer />

        </Fragment>
    );
}

export default NetherlandsCloud;