import React, { Fragment, useEffect, useState, useRef } from "react";
import ThemeSwitcher from "../pages/ThemeSwitcher";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

const Header = () => {
    const location = useLocation();
    const assets = "/assets";
    const [v_p_s, set_Vps] = useState([]);
    const [rdps, set_Rdps] = useState([]);
    const [rdp_menu, set_Rdp_Menu] = useState([]);
    const [rdp_location, set_Rdp_location] = useState([]);
    const [dedicated, set_Dedicated] = useState([]);

    useEffect(() => {
        axios
            .get("https://admin.digirdp.chaipost.co.in/api/windows_rdps")
            .then((response) => {
                set_Rdp_Menu(response.data[0]);
                set_Rdps(response.data[1]);
                if (response.data[0].length > 0) {
                    setActiveTab(response.data[0][0].menu_item_name); // Set the first tab as active initially
                }
            })
            .catch((error) => {
                console.error("Error fetching RDP data:", error);
            });
    }, []);

    const rdpPerColumn = rdps.length / 2;
    const chunkedrdp = rdps.length > 0 ? chunk(rdps, rdpPerColumn) : [];

    useEffect(() => {
        axios
            .get("https://admin.digirdp.chaipost.co.in/api/cloud_vps")
            .then((response) => {
                set_Vps(response.data);
            })
            .catch((error) => {
                console.error("Error fetching VPS data:", error);
            });
    }, []);

    const vpsPerColumn = v_p_s.length / 2;
    const chunkedVps = v_p_s.length > 0 ? chunk(v_p_s, vpsPerColumn) : [];

    useEffect(() => {
        axios
            .get("https://admin.digirdp.chaipost.co.in/api/dedicated_server")
            .then((response) => {
                set_Dedicated(response.data);
            })
            .catch((error) => {
                console.error("Error fetching Dedicated data:", error);
            });
    }, []);

    const dedicatedPerColumn = dedicated.length / 2;
    const chunkeddedicated =
        dedicated.length > 0 ? chunk(dedicated, dedicatedPerColumn) : [];

    useEffect(() => {
        axios
            .get("https://admin.digirdp.chaipost.co.in/api/r_d_p_by_locations")
            .then((response) => {
                set_Rdp_location(response.data);
            })
            .catch((error) => {
                console.error("Error fetching VPS data:", error);
            });
    }, []);

    const rdp_locationPerColumn = rdp_location.length / 2;
    const chunkedRdp_location =
        rdp_location.length > 0 ? chunk(rdp_location, rdp_locationPerColumn) : [];

    function chunk(arr, size) {
        return Array.from(Array(Math.ceil(arr.length / size)), (_, i) =>
            arr.slice(i * size, i * size + size)
        );
    }

    useEffect(() => {
        const menuCurrentLink = () => {
            const currentPage = location.pathname.split("/");
            const current = currentPage[currentPage.length - 1];

            const highlightCurrentLink = (selector) => {
                $(selector).each(function () {
                    const $this = $(this);
                    if ($this.attr("href") === current) {
                        $this.addClass("active");
                        $this.parents(".has-menu-child-item").addClass("menu-item-open");
                    }
                });
            };

            highlightCurrentLink(".dashboard-mainmenu li a");
            highlightCurrentLink(".mainmenu li a");
            highlightCurrentLink(".user-nav li a");
        };

        const popupMobileMenu = () => {
            // Open mobile menu
            $(".hamberger-button").on("click", () => {
                $(".popup-mobile-menu").addClass("active");
            });

            // Close mobile menu
            $(".close-menu").on("click", () => {
                $(".popup-mobile-menu").removeClass("active");
                closeAllDropdowns(); // Close all dropdowns when the menu is closed
            });

            // Toggle dropdown functionality for mobile menu
            $(
                ".popup-mobile-menu .mainmenu .has-dropdown > a, .popup-mobile-menu .mainmenu .with-megamenu > a"
            ).on("click", function (e) {
                e.preventDefault(); // Prevent default anchor behavior

                const $submenu = $(this).siblings(".submenu, .rainbow-megamenu"); // Target submenu
                const isOpen = $submenu.hasClass("active");

                // Close all other dropdowns
                $(".popup-mobile-menu .submenu, .popup-mobile-menu .rainbow-megamenu")
                    .not($submenu)
                    .removeClass("active")
                    .slideUp(300);

                $(
                    ".popup-mobile-menu .mainmenu .has-dropdown > a, .popup-mobile-menu .mainmenu .with-megamenu > a"
                )
                    .not(this)
                    .removeClass("open");

                // Toggle current dropdown
                if (!isOpen) {
                    $submenu.addClass("active").slideDown(300); // Animate dropdown open
                    $(this).addClass("open"); // Add "open" class to the link
                } else {
                    $submenu.removeClass("active").slideUp(300); // Animate dropdown close
                    $(this).removeClass("open"); // Remove "open" class
                }
            });

            // Helper function to close all dropdowns
            const closeAllDropdowns = () => {
                $(
                    ".popup-mobile-menu .mainmenu .submenu, .popup-mobile-menu .mainmenu .rainbow-megamenu"
                )
                    .removeClass("active")
                    .slideUp(300); // Close all dropdowns
                $(
                    ".popup-mobile-menu .mainmenu .has-dropdown > a, .popup-mobile-menu .mainmenu .with-megamenu > a"
                ).removeClass("open"); // Remove "open" class from all links
            };
        };

        const popupDislikeSection = () => {
            $(".dislike-section-btn").on("click", () => {
                $(".popup-dislike-section").addClass("active");
            });

            $(".close-button").on("click", () => {
                $(".popup-dislike-section").removeClass("active");
            });
        };

        const popupleftdashboard = () => {
            const updateSidebar = () => {
                if ($(window).width() >= 1600) {
                    $(".popup-dashboardleft-btn").removeClass("collapsed");
                    $(".popup-dashboardleft-section").removeClass("collapsed");
                } else {
                    $(".popup-dashboardleft-btn").addClass("collapsed");
                    $(".popup-dashboardleft-section").addClass("collapsed");
                }
            };

            $(
                ".popup-dashboardleft-btn, .popup-dashboardleft-section, .rbt-main-content, .rbt-static-bar"
            ).hide();

            updateSidebar();

            $(
                ".popup-dashboardleft-btn, .popup-dashboardleft-section, .rbt-main-content, .rbt-static-bar"
            ).show();

            $(window).on("resize", updateSidebar);

            $(".popup-dashboardleft-btn").on("click", () => {
                $(".popup-dashboardleft-btn").toggleClass("collapsed");
                $(".popup-dashboardleft-section").toggleClass("collapsed");
            });
        };

        const popuprightdashboard = () => {
            const updateSidebar = () => {
                if ($(window).width() >= 1600) {
                    $(".popup-dashboardright-btn").removeClass("collapsed");
                    $(".popup-dashboardright-section").removeClass("collapsed");
                } else {
                    $(".popup-dashboardright-btn").addClass("collapsed");
                    $(".popup-dashboardright-section").addClass("collapsed");
                }
            };

            $(
                ".popup-right-btn, .popup-right-section, .rbt-main-content, .rbt-static-bar"
            ).hide();

            updateSidebar();

            $(
                ".popup-right-btn, .popup-right-section, .rbt-main-content, .rbt-static-bar"
            ).show();

            $(window).on("resize", updateSidebar);

            $(".popup-dashboardright-btn").on("click", () => {
                $(".popup-dashboardright-btn").toggleClass("collapsed");
                $(".popup-dashboardright-section").toggleClass("collapsed");
            });
        };

        // Initialize all functions
        menuCurrentLink();
        popupMobileMenu();
        popupDislikeSection();
        popupleftdashboard();
        popuprightdashboard();

        // Cleanup function
        return () => {
            $(".hamberger-button, .close-menu, .popup-mobile-menu").off();
            $(".popup-dashboardleft-btn, .popup-dashboardright-btn").off();
            $(window).off("resize");
        };
    }, [location.pathname]);

    // Smooth scroll for links
    useEffect(() => {
        $(document).on("click", ".smoth-animation", function (event) {
            event.preventDefault();
            $("html, body").animate(
                {
                    scrollTop: $($.attr(this, "href")).offset().top - 50,
                },
                300
            );
        });
    }, []);

    // Popup Dislike Section
    useEffect(() => {
        $(".dislike-section-btn").on("click", function () {
            $(".popup-dislike-section").addClass("active");
        });

        $(".close-button").on("click", function () {
            $(".popup-dislike-section").removeClass("active");
        });
    }, []);

    useEffect(() => {
        // Sticky header function
        const headerSticky = () => {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 250) {
                    $(".header-sticky").addClass("sticky");
                } else {
                    $(".header-sticky").removeClass("sticky");
                }
            });
        };

        // Call the sticky header function when component mounts
        headerSticky();

        // Clean up the scroll event on component unmount
        return () => {
            $(window).off("scroll");
        };
    }, []);

    // useEffect to run the jQuery script after the component is mounted
    useEffect(() => {
        // jQuery plugin for 'Show More / Show Less' functionality
        const showMoreBtn = () => {
            $.fn.hasShowMore = function () {
                return this.each(function () {
                    $(this).toggleClass("active");
                    $(this).text("Show Less");
                    $(this).parent(".has-show-more").toggleClass("active");

                    if ($(this).parent(".has-show-more").hasClass("active")) {
                        $(this).text("Show Less");
                    } else {
                        $(this).text("Show More");
                    }
                });
            };

            // Bind the click event to the 'Show More / Show Less' button
            $(document).on("click", ".rbt-show-more-btn", function () {
                $(this).hasShowMore();
            });
        };

        // Call the function when the component is mounted
        showMoreBtn();

        // Clean up jQuery events when the component unmounts
        return () => {
            $(document).off("click", ".rbt-show-more-btn");
        };
    }, []); // Empty dependency array ensures it runs once when component mounts

    const [activeTab, setActiveTab] = useState([]); // Default active tab
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle mega menu visibility
    const dropdownRef = useRef(null);

    // Function to handle tab clicks
    const handleTabClick = (menuItemName) => {
        setActiveTab(menuItemName);
    };

    // Handle hover to open the menu
    const handleMouseEnter = () => {
        setIsMenuOpen(true);
    };

    // Handle mouse leave to close the menu
    const handleMouseLeave = () => {
        setIsMenuOpen(false);
    };

    // Close the menu if the user clicks outside
    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsMenuOpen(false);
        }
    };

    // Add event listener for clicks outside the menu
    React.useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    // const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // Adjust this delay as needed

        return () => clearTimeout(timer); // Cleanup on component unmount
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer); // cleanup timer when component unmounts
    }, []);


    return (
        <Fragment>

            {/* <!-- Imroz Preloader --> */}
            {loading && (
                <div className="preloader">
                    <div className="loader">
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                    </div>
                </div>
            )}

            {/* <!-- Start Header Top Area  --> */}
            <div className="header-top-news bg-image1">
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="content">
                                        <span className="rainbow-badge">Limited Time Offer</span>
                                        <span className="news-text">
                                            Find & Buy Best RDP and Dedicated Cloud VPS servers
                                        </span>
                                    </div>
                                    <div className="right-button">
                                        <a className="btn-read-more" href="/">
                                            <span>
                                                Purchase Now{" "}
                                                <i className="fa-sharp fa-regular fa-arrow-right"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="icon-close">
                    <button className="close-button bgsection-activation">
                        <i className="fa-sharp fa-regular fa-x"></i>
                    </button>
                </div>
            </div>
            {/* <!-- End Header Top Area  --> */}

            <ThemeSwitcher />

            {/* <!-- Start Header Area  --> */}
            <header className="rainbow-header header-default header-transparent header-sticky">
                <div className="container position-relative">
                    <div className="row align-items-center row--0">
                        <div className="col-lg-2 col-md-6 col-6">
                            <div className="logo">
                                <a href="/">
                                    <img
                                        className="logo-light"
                                        src={`${assets}/images/logo/logo.png`}
                                        alt="ChatBot Logo"
                                    />
                                    <img
                                        className="logo-dark"
                                        src={`${assets}/images/light/logo/logo-dark.png`}
                                        alt="ChatBot Logo"
                                    />
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-8 d-none d-lg-block">
                            <nav className="mainmenu-nav d-none d-lg-flex justify-content-center">
                                <ul className="mainmenu">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li
                                        className="with-megamenu has-menu-child-item"
                                        ref={dropdownRef}
                                        onMouseEnter={() => setIsMenuOpen(true)} // Open menu on hover
                                        onMouseLeave={() => setIsMenuOpen(false)} // Close menu on hover leave
                                    >
                                        <Link to="#" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                                            Windows RDP
                                        </Link>
                                        {isMenuOpen && (
                                            <div className="rainbow-megamenu rainbow-megamenu-new">
                                                <div className="wrapper wrapper-new">
                                                    <div className="mega-menu-content">
                                                        <div className="tabsNew">
                                                            {/* Render tabs dynamically */}
                                                            {rdp_menu.map((menu, index) => (
                                                                <div
                                                                    key={index}
                                                                    className={`tabNew ${activeTab === menu.menu_item_name ? "active" : ""}`}
                                                                    onClick={() => handleTabClick(menu.menu_item_name)}
                                                                >
                                                                    {menu.menu_item_name}
                                                                </div>
                                                            ))}
                                                        </div>

                                                        {/* Tab Content */}
                                                        <div className="tab-content-new">
                                                            {rdp_menu.map((menu, index) => (
                                                                <div
                                                                    key={index}
                                                                    id={menu.menu_item_name}
                                                                    className={`tab-pane-new ${activeTab === menu.menu_item_name ? "active" : ""}`}
                                                                >
                                                                    <div className="row">
                                                                        {rdps
                                                                            .filter((rdp) => rdp.menu_item_category_id === menu.menu_item_id)
                                                                            .filter((rdp) => rdp.show_in_header === 1) 
                                                                            .map((rdp, index) => (
                                                                                <div key={index} className="col-lg-4">
                                                                                    <ul className="mega-menu-item">

                                                                                        <li>
                                                                                            <Link to={`/rdp-plan/${rdp.url_text}`}>
                                                                                                <span>{rdp.name}</span>
                                                                                            </Link>
                                                                                        </li>

                                                                                    </ul>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </li>
                                    <li
                                        className={`with-megamenu has-menu-child-item ${isMenuOpen ? "active" : ""
                                            }`}
                                        onMouseEnter={() => setIsMenuOpen(true)}
                                        onMouseLeave={() => setIsMenuOpen(false)}
                                        onClick={toggleMenu}
                                    >
                                        <Link to="#">Dedicated Server </Link>
                                        <div className="rainbow-megamenu">
                                            <div className="wrapper">
                                                <div className="row row--0">
                                                    {dedicated.length > 0 && (
                                                        <>
                                                            {chunkeddedicated.map((column, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="col-lg-6 single-mega-item"
                                                                >
                                                                    <ul className="mega-menu-item">
                                                                        {column
                                                                        .filter((dedicate) => dedicate.show_in_header === 1) 
                                                                        .map((dedicate, index) => (
                                                                            <li key={index}>
                                                                                <Link
                                                                                    to={`/dedicated-server/${dedicate.url_text}`}
                                                                                >
                                                                                    <span>{dedicate.name}</span>
                                                                                </Link>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li
                                        className={`with-megamenu has-menu-child-item ${isMenuOpen ? "active" : ""
                                            }`}
                                        onMouseEnter={() => setIsMenuOpen(true)}
                                        onMouseLeave={() => setIsMenuOpen(false)}
                                        onClick={toggleMenu}
                                    >
                                        <Link to="#">Cloud VPS</Link>
                                        <div className="rainbow-megamenu">
                                            <div className="wrapper">
                                                <div className="row row--0">
                                                    {v_p_s.length > 0 && (
                                                        <>
                                                            {chunkedVps.map((column, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="col-lg-6 single-mega-item"
                                                                >
                                                                    <ul className="mega-menu-item">
                                                                        {column
                                                                        .filter((vps) => vps.show_in_header === 1) 
                                                                        .map((vps, index) => (
                                                                            <li key={index}>
                                                                                <Link to={`/cloud-vps/${vps.url_text}`}>
                                                                                    <span>{vps.name}</span>
                                                                                </Link>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li
                                        className={`with-megamenu has-menu-child-item ${isMenuOpen ? "active" : ""
                                            }`}
                                        onMouseEnter={() => setIsMenuOpen(true)}
                                        onMouseLeave={() => setIsMenuOpen(false)}
                                        onClick={toggleMenu}
                                    >
                                        <Link to="#">Private RDP</Link>
                                        <div className="rainbow-megamenu">
                                            <div className="wrapper">
                                                <div className="row row--0">
                                                    {rdp_location.length > 0 && (
                                                        <>
                                                            {chunkedRdp_location.map((column, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="col-lg-6 single-mega-item"
                                                                >
                                                                    <ul className="mega-menu-item">
                                                                        {column
                                                                        .filter((rdp) => rdp.show_in_header === 1) 
                                                                        .map((rdp, index) => (
                                                                            <li key={index}>
                                                                                <Link
                                                                                    to={`/private_rdp/${rdp.url_text}`}
                                                                                >
                                                                                    <span>{rdp.name}</span>
                                                                                </Link>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="has-dropdown has-menu-child-item position-relative">
                                        <Link to="#">Support </Link>
                                        <ul className="submenu">
                                            <li>
                                                <Link to={`/contact`}>
                                                    <span>Contact</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/knowledge-base`}>
                                                    <span>Knowledge Based</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <a target="_blank" rel="noreferrer" href="https://manage.digirdp.com/submitticket.php">
                                                    <span>Support Ticket </span>
                                                </a>
                                            </li>
                                            <li>
                                                <Link to={`/blog`}>
                                                    <span>Blog</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <a href="/glass-looking" rel="noreferrer">
                                                    <span>Looking Glass</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://status.digirdp.com/" target="_blank" rel="noreferrer">
                                                    <span>Service Stats</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div className="col-lg-2 col-md-6 col-6 position-static">
                            <div className="header-right">
                                {/* <!-- Start Header Btn  --> */}
                                <div className="header-btn">
                                    <a className="rainbow-gradient-btn" target="_blank" href="/">
                                        <span>Client Area</span>
                                    </a>
                                </div>
                                {/* <!-- End Header Btn  --> */}

                                {/* <!-- Start Mobile-Menu-Bar --> */}
                                <div className="mobile-menu-bar ml--5 d-flex justify-content-end d-lg-none">
                                    <div className="hamberger">
                                        <button className="hamberger-button">
                                            <i className="fa-sharp fa-regular fa-bars"></i>
                                        </button>
                                    </div>
                                </div>
                                {/* <!-- Start Mobile-Menu-Bar --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!-- End Header Area  --> */}
            <div className="popup-mobile-menu">
                <div className="inner-popup">
                    <div className="header-top">
                        <div className="logo">
                            <a href="/">
                                <img
                                    className="logo-light"
                                    src={`${assets}/images/logo/logo.png`}
                                    alt="ChatBot Logo"
                                />
                            </a>
                        </div>
                        <div className="close-menu">
                            <button className="close-button">
                                <i className="fa-sharp fa-regular fa-x"></i>
                            </button>
                        </div>
                    </div>

                    <div className="content">
                        <nav className="mainmenu-nav">
                            <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                            </ul>
                            <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                                <li className="has-submenu">
                                    <a
                                        className="collapse-btn collapsed"
                                        data-bs-toggle="collapse"
                                        href="#collapseExampleMenuOne"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="collapseExampleMenuOne"
                                    >
                                        <span>Windows RDP</span>
                                    </a>
                                    {rdp_menu.map((menu, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="collapse"
                                                id="collapseExampleMenuOne"
                                            >
                                                <ul className="submenu rbt-default-sidebar-list">
                                                    <li>
                                                        <h3 className="rbt-short-title">
                                                            {menu.menu_item_name}
                                                        </h3>
                                                    </li>
                                                    {rdps
                                                        .filter(
                                                            (rdp) =>
                                                                rdp.menu_item_category_id === menu.menu_item_id
                                                        )
                                                        .filter((rdp) => rdp.show_in_header === 1) 
                                                        .map((rdp, index) => (
                                                            <li>
                                                                <Link to={`/rdp-plan/${rdp.url_text}`}>
                                                                    <span>{rdp.name}</span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        );
                                    })}
                                </li>
                            </ul>
                            <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                                <li className="has-submenu">
                                    <a
                                        className="collapse-btn collapsed"
                                        data-bs-toggle="collapse"
                                        href="#collapseExampleMenuTwo"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="collapseExampleMenuTwo"
                                    >
                                        <span>Dedicated Server</span>
                                    </a>
                                    <div className="collapse" id="collapseExampleMenuTwo">
                                        <ul className="submenu rbt-default-sidebar-list">
                                            {dedicated
                                            .filter((dedicate) => dedicate.show_in_header === 1) 
                                            .map((dedicate, index) => (
                                                <li>
                                                    <Link to={`/dedicated-server/${dedicate.url_text}`}>
                                                        <span>{dedicate.name}r</span>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                                <li className="has-submenu">
                                    <a className="collapse-btn collapsed" data-bs-toggle="collapse" href="#collapseExampleMenuThree" role="button" aria-expanded="false" aria-controls="collapseExampleMenuThree">
                                        <span>Cloud VPS</span>
                                    </a>
                                    <div className="collapse" id="collapseExampleMenuThree">
                                        <ul className="submenu rbt-default-sidebar-list">
                                            {v_p_s
                                            .filter((vps) => vps.show_in_header === 1) 
                                            .map((vps, index) => (
                                                <li>
                                                    <Link to={`/cloud-vps/${vps.url_text}`}>
                                                        <span>{vps.name}</span>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                                <li className="has-submenu">
                                    <a className="collapse-btn collapsed" data-bs-toggle="collapse" href="#collapseExampleMenuFour" role="button" aria-expanded="false" aria-controls="collapseExampleMenuFour">
                                        <span>Private RDP</span>
                                    </a>
                                    <div className="collapse" id="collapseExampleMenuFour">
                                        <ul className="submenu rbt-default-sidebar-list">
                                            {rdp_location
                                            .filter((rdp_loc) => rdp_loc.show_in_header === 1) 
                                            .map((rdp_loc, index) => (
                                                <li>
                                                    <Link to={`/private_rdp/${rdp_loc.url_text}`}>
                                                        <span>{rdp_loc.name}</span>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                                <li className="has-submenu">
                                    <a className="collapse-btn collapsed" data-bs-toggle="collapse" href="#collapseExampleMenuFive" role="button" aria-expanded="false" aria-controls="collapseExampleMenuFive">
                                        <span>Support</span>
                                    </a>
                                    <div className="collapse" id="collapseExampleMenuFive">
                                        <ul className="submenu rbt-default-sidebar-list">
                                            <li>
                                                <Link to={`/contact`}>
                                                    <span>Contact</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/knowledge-base`}>
                                                    <span>Knowledge Based</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <a target="_blank" rel="noreferrer" href="https://manage.digirdp.com/submitticket.php">
                                                    <span>Support Ticket </span>
                                                </a>
                                            </li>
                                            <li>
                                                <Link to={`/blog`}>
                                                    <span>Blog</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <a href="/glass-looking" rel="noreferrer">
                                                    <span>Looking Glass</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://status.digirdp.com/" target="_blank" rel="noreferrer">
                                                    <span>Service Stats</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <div className="rbt-sm-separator"></div>
                            <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                                <li>
                                    <a href="/about">
                                        <i className="fa-regular fa-circle-question"></i>
                                        <span>About Us</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/contact">
                                        <i className="fa-sharp fa-regular fa-bell"></i>
                                        <span>Contact Us</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/terms">
                                        <i className="fa-sharp fa-regular fa-briefcase"></i>
                                        <span>Terms & Policy</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    {/* <!-- Start Header Btn  --> */}
                    <div className="header-btn d-block d-md-none">
                        <a className="btn-default @@btnclassName" target="_blank" href="/">
                            Client Area
                        </a>
                    </div>
                    {/* <!-- End Header Btn  --> */}
                </div>
            </div>

        </Fragment>
    );
};

export default Header;
