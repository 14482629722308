import React, { Fragment } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Location from "../sales-page/Location";
// import $ from 'jquery';
// import Testimonial from "../slider/testimonial";
// import BlogSlider from "../slider/BlogSlider";
// import WhyUs from "../slider/WhyUs";
// const assets = '/assets';

const Affiliate = () => {



    return (
        <Fragment>
            <Header />


            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">Join Our Affiliate Program & Earn Big! </h3>
                                    <p className="description b1">Join Our Affiliate Program & Earn Big!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center" >
                                    <h2 className="title w-600 mb--20">Earn Recurring Revenue</h2>
                                    <p>Join our affiliate program today and unlock the exciting opportunity to earn recurring revenue by referring customers to DigiRDP's premium RDP and VPS services. With every successful referral, you'll earn commissions not just once, but for every renewal your customer makes.</p>
                                    <p>As an affiliate, you’ll gain access to powerful tools and support to help you grow your earnings while promoting high-quality, fast, and secure cloud solutions. Start building a steady income stream today by becoming a DigiRDP affiliate and referring customers worldwide!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}

                {/* <!-- Start Advanced Tab area --> */}
                <div className="rainbow-advance-tab-area aiwave-bg-gradient rainbow-section-gap-big">
                    <div className="container">
                        <div className="html-tabs" data-tabs="true">
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-12">
                                    <div className="section-title text-center" >
                                        <h2 className="title w-600 mb--20">How It Works</h2>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div>
                                        <h4>Sign Up :</h4>
                                        <p>Register for free and become an official DigiRDP affiliate.</p>
                                        <h4>Promote :</h4>
                                        <p>Share your unique referral link through websites, blogs, or social media.</p>
                                        <h4>Earn :</h4>
                                        <p>Get paid for every customer you refer who makes a purchase.</p>
                                        <h5>Questions about our program or how to get started?</h5>
                                        <p>No problem! Just contact us at <a href="/contact">https://digirdp.chaipost.co.in/contact</a> and we’ll get you started!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Start Pricing Area  --> */}


            <Location />
            <Footer />

        </Fragment>
    );
}

export default Affiliate;