import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Testimonial from "./slider/testimonial";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sal from 'sal.js';
import 'sal.js/dist/sal.css';
import BlogSlider from "./slider/BlogSlider";
import FeatureSlider from "./slider/FeatureSlider";
import Location from "./sales-page/Location";
import Categories from "./slider/Categories";
import axios from "axios";
const assets = '/assets';

const Home = () => {
    useEffect(() => {
        sal();
    }, []);

    const [winrdp, setWinRDP] = useState([]);
    const [cloudvps, setCloudVPS] = useState([]);
    const [dedicated, setdedicated] = useState([]);

    useEffect(()=>{
        axios.get("https://admin.digirdp.chaipost.co.in/api/triooffer").then((response)=>{
             console.log("Clud "+response.data[1]);
             console.log("Dedicated"+response.data[0]);
             console.log("Win"+response.data[2]);
             setWinRDP(response.data[0]);
             setCloudVPS(response.data[2]);
             setdedicated(response.data[1]);
        })
        .catch((error)=>{
         console.log("offer didn't fetch", error);
        })
 
 
 }, [])


    const [btnText, setBtnText] = useState("Copy Code");
    const [btnText1, setBtnText1] = useState("Copy Code");
    const [btnText2, setBtnText2] = useState("Copy Code");
    const [btnText3, setBtnText3] = useState("Copy Code");

    const handleCopy = (couponCode, setButtonText) => {
        navigator.clipboard
        .writeText(couponCode)
        .then(() => {
            setButtonText("COPIED!");
            setTimeout(() => setButtonText("Copy Code"), 3000);
        })
        .catch(() => {
            console.error("Failed to copy the code");
        });
    };

    return (
        <Fragment>
            <Header />
            {/* main top */}
            <div className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1 slider-bg-shape" data-black-overlay="1">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="inner text-center mt--140">
                                <h1 className="title display-one">DigiRDP Your Gateway to
                                    <br /> <span className="header-caption">
                                        <span className="cd-headline rotate-1">
                                            <span className="cd-words-wrapper" style={{ width: "497px" }}>
                                                <b className="theme-gradient is-visible">Unparalleled</b>
                                                <b className="theme-gradient is-hidden">Unparalleled</b>
                                                <b className="theme-gradient is-hidden">Unparalleled</b>
                                            </span>
                                        </span>
                                    </span><br /> Cloud Performance
                                </h1>
                                <p className="description">Find & Buy Best RDP and Dedicated Cloud VPS servers <br /> conversation app Rainbow theme</p>
                                <a className="btn-default @@btnclassName" href="/contact">Contact Us Now</a>
                                <div className="inner-shape">
                                    <img src={`${assets}/images/bg/icon-shape/icon-shape-one.png`} alt="Icon Shape" className="iconshape iconshape-one" />
                                    <img src={`${assets}/images/bg/icon-shape/icon-shape-two.png`} alt="Icon Shape" className="iconshape iconshape-two" />
                                    <img src={`${assets}/images/bg/icon-shape/icon-shape-three.png`} alt="Icon Shape" className="iconshape iconshape-three" />
                                    <img src={`${assets}/images/bg/icon-shape/icon-shape-four.png`} alt="Icon Shape" className="iconshape iconshape-four" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-shape">
                    <img className="bg-shape-one" src={`${assets}/images/bg/bg-shape-four.png`} alt="Bg Shape" />
                    <img className="bg-shape-two" src={`${assets}/images/bg/bg-shape-five.png`} alt="Bg Shape" />
                </div>
            </div>

            {/* <!-- Start Brand Area --> */}
            <div className="rainbow-brand-area rainbow-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title rating-title text-center sal-animate" data-sal="slide-up"
                                data-sal-duration="700" data-sal-delay="100">
                                <p className="b1 mb--0 small-title">truest 800,000+ HIGHLY PRODUCTIVE Company</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mt--10">
                            <div className="brand-slider-container">
                                <ul className="brand-list brand-style-2 slider-brand">
                                    <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/1d.png`} alt="Brand" /></a></li>
                                    <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/2d.svg`} alt="Brand" /></a></li>
                                    <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/3d.png`} alt="Brand" /></a></li>
                                    <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/4d.webp`} alt="Brand" /></a></li>
                                    <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/5d.png`} alt="Brand" /></a></li>
                                    <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/6d.png`} alt="Brand" /></a></li>
                                    <li className="slide-single-layout"><a href="/"><img src={`${assets}/images/added/7d.svg`} alt="Brand" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Start Tab__Style--one Area  --> */}
            <div className="rainbow-service-area rainbow-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center pb--60" data-sal="slide-up" data-sal-duration="700"
                                data-sal-delay="100">
                                <h4 className="subtitle">
                                    <span className="theme-gradient">We Offer</span>
                                </h4>
                                <h2 className="title mb--0">Our Best Services</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row row--30 align-items-center">
                        <div className="col-lg-12">
                            <div className="rainbow-default-tab style-three generator-tab-defalt">
                                <ul className="nav nav-tabs tab-button" role="tablist">
                                    <li className="nav-item tabs__tab " role="presentation">
                                        <button className="nav-link rainbow-gradient-btn without-shape-circle active"
                                            id="dash-generator-tab" data-bs-toggle="tab" data-bs-target="#dash-generate"
                                            type="button" role="tab" aria-controls="dash-generate" aria-selected="false">
                                            <span className="generator-icon"><img src={`${assets}/images/added/dash.svg`}
                                                alt="Vedio Generator Icon" />Dashboard</span>
                                            <span className="border-bottom-style"></span>
                                        </button>
                                    </li>
                                    <li className="nav-item tabs__tab " role="presentation">
                                        <button className="nav-link rainbow-gradient-btn without-shape-circle"
                                            id="video-generator-tab" data-bs-toggle="tab" data-bs-target="#video-generate"
                                            type="button" role="tab" aria-controls="video-generate" aria-selected="false">
                                            <span className="generator-icon"><img src={`${assets}/images/added/Safe.svg`}
                                                alt="Vedio Generator Icon" />Date Safe & Secure</span>
                                            <span className="border-bottom-style"></span>
                                        </button>
                                    </li>
                                    <li className="nav-item tabs__tab" role="presentation">
                                        <button className="nav-link rainbow-gradient-btn without-shape-circle "
                                            id="audio-generator-tab" data-bs-toggle="tab" data-bs-target="#audio-generate"
                                            type="button" role="tab" aria-controls="audio-generate" aria-selected="true">
                                            <span className="generator-icon"><img src={`${assets}/images/added/fast.svg`}
                                                alt="Vedio Generator Icon" />Fast & Reliable</span>
                                            <span className="border-bottom-style"></span>
                                        </button>
                                    </li>
                                    <li className="nav-item tabs__tab " role="presentation">
                                        <button className="nav-link rainbow-gradient-btn without-shape-circle"
                                            id="photo-generator-tab" data-bs-toggle="tab" data-bs-target="#photo-generate"
                                            type="button" role="tab" aria-controls="photo-generate" aria-selected="false">
                                            <span className="generator-icon"><img src={`${assets}/images/added/24-support.svg`}
                                                alt="Vedio Generator Icon" />24/7 Expert Support</span>
                                            <span className="border-bottom-style"></span>
                                        </button>
                                    </li>
                                </ul>

                                <div className="rainbow-tab-content tab-content">
                                    <div className="tab-pane fade show active" id="dash-generate" role="tabpanel" aria-labelledby="video-generator-tab">
                                        <div className="inner">
                                            <div className="row">
                                                <div className="col-12 mt_md--30 mt_sm--30">
                                                    <div className="export-img">
                                                        <div className="inner-without-padding">
                                                            <div className="export-img img-bg-shape">
                                                                <img src={`${assets}/images/added/dashboard.jpg`} alt="Chat example" />
                                                                <div className="image-shape"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="video-generate" role="tabpanel"
                                        aria-labelledby="video-generator-tab">
                                        <div className="inner">
                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 col-12">
                                                    <div className="section-title">
                                                        <h2 className="title"> Date Safe & Secure</h2>
                                                        <p>Nothing is more frustrating than a remote working solution that
                                                            is hampered by attacks but fear not because DigiRDP servers are
                                                            encrypted and fully secured. So, you can enjoy a fully secure
                                                            data management.</p>
                                                        <div className="features-section">
                                                            <ul className="list-style--1">
                                                                <li><i className="fa-regular fa-circle-check"></i>Encrypted
                                                                    Servers</li>
                                                                <li><i className="fa-regular fa-circle-check"></i>Attack
                                                                    Protection</li>
                                                                <li><i className="fa-regular fa-circle-check"></i>Secure
                                                                    Management</li>
                                                                <li><i className="fa-regular fa-circle-check"></i>Reliable
                                                                    Encryption</li>
                                                            </ul>
                                                        </div>
                                                        <div className="read-more">
                                                            <a className="btn-default color-blacked" href="/">Start Exploring
                                                                Now <i className="fa-sharp fa-solid fa-arrow-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-md-6 col-12 mt_md--30 mt_sm--30">
                                                    <div className="export-img">
                                                        <div className="inner-without-padding">
                                                            <div className="export-img img-bg-shape">
                                                                <img src={`${assets}/images/added/secure.jpg`} alt="Chat example" />
                                                                <div className="image-shape"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade " id="audio-generate" role="tabpanel"
                                        aria-labelledby="audio-generator-tab">
                                        <div className="inner">
                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 col-12">
                                                    <div className="section-title">
                                                        <h2 className="title">Fast & Reliable</h2>
                                                        <p>We only use SSD on our RDP servers which is ten times faster than
                                                            normal RDP or HDD. Solid-State drives give best performance and
                                                            reliability.</p>
                                                        <div className="features-section">
                                                            <ul className="list-style--1">
                                                                <li><i className="fa-regular fa-circle-check"></i>SSD
                                                                    Performance</li>
                                                                <li><i className="fa-regular fa-circle-check"></i>Speed &
                                                                    Efficiency</li>
                                                                <li><i className="fa-regular fa-circle-check"></i>High
                                                                    Reliability</li>
                                                                <li><i className="fa-regular fa-circle-check"></i>Optimal Speed
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="read-more">
                                                            <a className="btn-default color-blacked" href="/">Start Exploring
                                                                Now <i className="fa-sharp fa-solid fa-arrow-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-md-6 col-12 mt_md--30 mt_sm--30">
                                                    <div className="export-img">
                                                        <div className="inner-without-padding">
                                                            <div className="export-img img-bg-shape">
                                                                <img className="shape-dark" src={`${assets}/images/added/fast.jpg`} alt="Chat example" />
                                                                <img className="shape-light" src={`${assets}/images/added/fast.jpg`} alt="Chat example" />
                                                                <div className="image-shape"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="photo-generate" role="tabpanel"
                                        aria-labelledby="photo-generator-tab">
                                        <div className="inner">
                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 col-12">
                                                    <div className="section-title">
                                                        <h2 className="title">24/7 Expert Support</h2>
                                                        <p>There is a technical issue you need support with, or anything
                                                            goes wrong with your RDP at any point, our team of professionals
                                                            will happily assist you anytime you need us.</p>
                                                        <div className="features-section">
                                                            <ul className="list-style--1">
                                                                <li><i className="fa-regular fa-circle-check"></i>Always
                                                                    Available</li>
                                                                <li><i className="fa-regular fa-circle-check"></i>Quick
                                                                    Assistance</li>
                                                                <li><i className="fa-regular fa-circle-check"></i>Professional
                                                                    Help</li>
                                                                <li><i
                                                                    className="fa-regular fa-circle-check"></i>Round-the-Clock
                                                                    Support</li>
                                                            </ul>
                                                        </div>
                                                        <div className="read-more">
                                                            <a className="btn-default color-blacked" href="/">Start Exploring
                                                                Now <i className="fa-sharp fa-solid fa-arrow-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-md-6 col-12 mt_md--30 mt_sm--30">
                                                    <div className="export-img">
                                                        <div className="inner-without-padding">
                                                            <div className="export-img img-bg-shape">
                                                                <img src={`${assets}/images/added/expert.jpg`}
                                                                    alt="Chat example" />
                                                                <div className="image-shape"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Start Service__Style--one Area  --> */}
            <div className="rainbow-service-area rainbow-section-gap rainbow-section-gapBottom-big">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-left" data-sal="slide-up" data-sal-duration="400"
                                data-sal-delay="150">
                                <h4 className="subtitle">
                                    <span className="theme-gradient">Assisting individuals</span>
                                </h4>
                                <h2 className="title mb--60">Our Features</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                <FeatureSlider />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Start Advanced Tab area --> */}
            <div className="rainbow-advance-tab-area aiwave-bg-gradient rainbow-section-gap-big">
                <div className="container">
                    <div className="html-tabs" data-tabs="true">
                        <div className="row row--30">
                            <div className="col-lg-12">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active advance-tab-content-1 right-top" id="home-3"
                                        role="tabpanel" aria-labelledby="home-tab-3">
                                        <div className="rainbow-splite-style">
                                            <div className="split-wrapper">
                                                <div className="row g-0 radius-10 align-items-center justify-content-between">
                                                    <div className="col-xl-5 col-sm-5 col-12">
                                                        <div className="thumbnail">
                                                            <img className="radius" src={`${assets}/images/added/dashboard.jpeg`}
                                                                alt="split Images" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-7 col-sm-6 col-12">
                                                        <div className="split-inner">
                                                            <div className="subtitle">
                                                                <span className="theme-gradient">Get Started!</span>
                                                            </div>
                                                            <h2 className="title sal-animate" data-sal="slide-up"
                                                                data-sal-duration="400" data-sal-delay="200">Here's What You
                                                                Get</h2>
                                                            <p className="description sal-animate" data-sal="slide-up"
                                                                data-sal-duration="400" data-sal-delay="300">When you
                                                                purchase any RDP Plans, you get access to a tools and
                                                                components to help you working with the page more
                                                                efficiently.</p>
                                                            <div className="view-more-button mt--35 sal-animate"
                                                                data-sal="slide-up" data-sal-duration="400"
                                                                data-sal-delay="400">
                                                                <a className="btn-default color-blacked" href="contact.html">Try
                                                                    It Now <i
                                                                        className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt--60">
                                <div className="advance-tab-button advance-tab-button-1 right-top">
                                    <ul className="nav nav-tabs tab-button-list">

                                        <li className="col-lg-3 nav-item" role="presentation">
                                            <a href="/rdp-plan" className="nav-link tab-button ">
                                                <div className="tab">
                                                    <div className="count-text">
                                                        <span className="theme-gradient">01</span>
                                                    </div>
                                                    <h4 className="title">Windows RDP </h4>
                                                    <p className="description sal-animate">Here for RDP? Look no where else, we
                                                        will setup Windows RDP tailored to your needs no matter the scale.
                                                    </p>
                                                </div>
                                            </a>
                                        </li>

                                        <li className="col-lg-3 nav-item" role="presentation">
                                            <a href="/dedicated-plan" className="nav-link tab-button">
                                                <div className="tab">
                                                    <div className="count-text">
                                                        <span className="theme-gradient">02</span>
                                                    </div>
                                                    <h4 className="title">Dedicated Servers</h4>
                                                    <p className="description sal-animate">With SSD storage, high-memory
                                                        variants and latest processors, use our servers to get the best
                                                        performance</p>
                                                </div>
                                            </a>
                                        </li>

                                        <li className="col-lg-3 nav-item" role="presentation">
                                            <a href="/cloud-vps-plan" className="nav-link tab-button">
                                                <div className="tab">
                                                    <div className="count-text">
                                                        <span className="theme-gradient">03</span>
                                                    </div>
                                                    <h4 className="title">Cloud VPS</h4>
                                                    <p className="description sal-animate">Our Cloud VPS service is best suited
                                                        for any kind of website, production environment or pre-production
                                                        environment.</p>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="col-lg-3 nav-item" role="presentation">
                                            <a href="/" className="nav-link tab-button">
                                                <div className="tab">
                                                    <div className="count-text">
                                                        <span className="theme-gradient">04</span>
                                                    </div>
                                                    <h4 className="title">24 X 7 Support</h4>
                                                    <p className="description sal-animate">We are available 24x7 without
                                                        creating any issues or downtime for your servers.</p>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-shape">
                    <img src={`${assets}/images/bg/split-bg-shape.png`} alt="Bg Shape" />
                </div>
            </div>

            {/* <!-- Start Service__Style--one Area  --> */}
            <div className="rainbow-service-area rainbow-section-gap rainbow-section-gapBottom-big">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-left" data-sal="slide-up" data-sal-duration="400"
                                data-sal-delay="150">
                                <h4 className="subtitle">
                                    <span className="theme-gradient">Tailored Services</span>
                                </h4>
                                <h2 className="title mb--60">Explore Our Categories</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                <Categories />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Start CTA Style-one Area  --> */}
            <div className="rainbow-rn-cta mt-5">
                <div className="container">
                    <div className="row row--0 align-items-center content-wrapper">
                        <div className="col-lg-8">
                            <div className="inner">
                                <div className="content text-left">
                                    <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                        data-sal-delay="200">Join our Experts community</h4>
                                    <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="right-content">
                                <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                    data-sal-duration="400" data-sal-delay="400">
                                    <div className="team-image">
                                        <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                    </div>
                                    <a className="btn-default" href="/contact">Join DigiRDP Experts</a>
                                </div>
                            </div>
                        </div>
                        <div className="bg-shape">
                            <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Pricing Part --> */}
            <div className="aiwave-pricing-area wrapper rainbow-section-gap-big">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center" data-sal="slide-up" data-sal-duration="400"
                                data-sal-delay="150">
                                <h4 className="subtitle">
                                    <span className="theme-gradient">Pricing</span>
                                </h4>
                                <h2 className="title w-600 mb--40">
                                    Pricing plans for everyone
                                </h2>
                            </div>

                            <nav className="aiwave-tab">
                                <div className="tab-btn-grp nav nav-tabs text-center justify-content-center" id="nav-tab"
                                    role="tablist">
                                    <button className="nav-link active" id="nav-month-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-month" type="button" role="tab" aria-controls="nav-month"
                                        aria-selected="false">
                                        Monthly
                                    </button>
                                    <button className="nav-link with-badge " id="nav-year-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-year" type="button" role="tab" aria-controls="nav-year"
                                        aria-selected="true">
                                        Yearly
                                    </button>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="tab-content p-0 bg-transparent border-0 border bg-light" id="nav-tabContent">
                        <div className="tab-pane fade active show" id="nav-month" role="tabpanel" aria-labelledby="nav-month-tab">
                            <div className="row">
                                <div className="col-lg-12">
                                    <nav className="aiwave-tab">
                                        <div className="tab-btn-grp nav nav-tabs text-center justify-content-center" id="nav-tab"
                                            role="tablist">
                                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab"
                                                data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                                                aria-selected="false">
                                                Popular Cloud VPS Plans
                                            </button>
                                            <button className="nav-link with-badge " id="nav-profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile"
                                                aria-selected="true">
                                                Popular Dedicated Server Plans
                                            </button>
                                            <button className="nav-link with-badge " id="nav-profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#nav-plan" type="button" role="tab" aria-controls="nav-plan"
                                                aria-selected="true">
                                                Popular RDP Plans
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="tab-content p-0 bg-transparent border-0 border bg-light" id="nav-tabContent">
                                <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    {/* <h4 className="title w-600 mb--40 text-center">Explore to our smart Cloud VPS plans</h4> */}
                                    <div className="row row--15 mt_dec--40">
                                        {cloudvps.map((vps, index)=> (
                                            <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className={`rainbow-pricing style-aiwave`}>
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-one">{vps.name}</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">${vps.offer_price} </span>
                                                                <span className="text d-flex"><span
                                                                    style={{ textDecoration: "line-through" }}>${vps.price}</span>/Per
                                                                    Month</span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default btn-border" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="coupon-card">
                                                            <div className="coupon-row">
                                                                <span id="cpnCode">STEALDEAL20</span>
                                                                <span id="cpnBtn" onClick={() => handleCopy("STEALDEAL20", setBtnText)}>
                                                                    {btnText}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i> {vps.users} user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> {vps.processor}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> {vps.cpu}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> {vps.traffic}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.os}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.ram}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.bandwidth}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> IP Address  {vps.ip}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.drives}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> {vps.uptime} uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.description}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.location}</li>
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="feature-badge" style={{ background: "#00ac69", color: " #fff" }} >{(vps.tag).charAt(0).toUpperCase() + vps.tag.slice(1).toLowerCase()}</div>
                                            </div>
                                        </div>
                                        ))};
                                        
                                       
                                        {/* <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className="rainbow-pricing style-aiwave active">
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-two">Cloud VPS Host IPV6</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">$1 </span>
                                                                <span className="text d-flex"><span
                                                                    style={{ textDecoration: "line-through" }}>$5.99</span>/Per
                                                                    Month</span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default color-blacked" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="coupon-card">
                                                            <div className="coupon-row">
                                                                <span id="cpnCode">STEALDEAL20</span>
                                                                <span id="cpnBtn" onClick={() => handleCopy("FASTDEAL50", setBtnText1)}>
                                                                {btnText1}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i> Single user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Dual Intel Xeon Processor</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 1 c / 40 t CPU</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Instant Setup or Up to 12hrs</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Linux Operating System</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 1Gb RAM</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> IP Address /64 IPV6</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>10Gb Disk - Raid 50</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 99.9% uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>Full Root Access</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Dedicated Control panel</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> USA- New York</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Free Daily Backup</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Semi-Annual/Annual billing only</li>
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="feature-badge">Popular</div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className="rainbow-pricing style-aiwave">
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-three">Cloud VPS Host Starter</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">$3</span>
                                                                <span className="text d-flex"><span style={{ textDecoration: "line-through" }} >$8.99</span>/Per Month</span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default btn-border" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="coupon-card">
                                                            <div className="coupon-row">
                                                                <span id="cpnCode">STEALDEAL20</span>
                                                                <span id="cpnBtn" onClick={() => handleCopy("FASTDEAL50", setBtnText2)}>
                                                                {btnText2}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i> Single user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Dual Intel Xeon Processor</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 1 c / 40 t CPU
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Instant Setup or
                                                                        Up to 12hrs</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Linux Operating
                                                                        System</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 1Gb RAM</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> IP Address /64
                                                                        IPV6+ 1 IPV4</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>15Gb Disk - Raid
                                                                        50</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 99.9% uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Full Root Access
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Dedicated Control
                                                                        panel</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> USA- New York
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Free Daily Backup
                                                                    </li>
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }} >Hot</div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="tab-pane fade " id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <div className="row row--15 mt_dec--40">
                                        {dedicated.map((dedicate, index) => (
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className="rainbow-pricing style-aiwave">
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-one">{dedicate.name}</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">${dedicate.offer_price}</span>
                                                                <span className="text d-flex"><span style={{ textDecoration: "line-through" }}>${dedicate.price}</span>/Per
                                                                    Month</span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default btn-border" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.users} user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.processor}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>Logical Cores: {dedicate.cpu}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.traffic}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.os}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.ram}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>IP Address{dedicate.ip}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.drives}
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.uptime}uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.description}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.location}</li>
                                                                    {/* <li><i className="fa-regular fa-circle-check"></i></li> */}
                                                                    
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }} >{(dedicate.tag).charAt(0).toUpperCase() + dedicate.tag.slice(1).toLowerCase()}</div>
                                            </div>
                                        </div>
                                        ))};
                                        
                                        {/* <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className="rainbow-pricing style-aiwave active">
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-two">Ryzen Master</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">$179</span>
                                                                <span className="text d-flex"><span style={{ textDecoration: "line-through" }}>$250</span>/Per
                                                                    Month</span>

                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default color-blacked" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i> Single user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> AMD Ryzen 9 5950X
                                                                        Processor</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Logical cores:
                                                                        32@ 3.40 GHz CPU</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Setup time 24hr
                                                                        to upto 3days</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Linux or any OS
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 128 GB DDR4 ECC
                                                                        RAM</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Bandwidth 1Gbps
                                                                        @unmetered</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> IP Address 1
                                                                        usable IP</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 99.99% uptime
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 1Gbps@50TB</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 16 Core Matisse
                                                                        (Zen3) Simultaneous Multithreading Virtualization (AMD-V)
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Germany</li>
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="feature-badge">Hot</div>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className="rainbow-pricing style-aiwave">
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-three">Dedicated M Pro v2</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">$109</span>
                                                                <span className="text d-flex"><span style={{ textDecoration: "line-through" }}>$299</span>/Per
                                                                    Month</span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default btn-border" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i> Single user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Intel® Core i5
                                                                        9600K Processor</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> @3.7 GHz (6C/6T)
                                                                        CPU</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Setup time 24hr
                                                                        to upto 3days</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Linux or any OS
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 64 GB DDR4 RAM
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Bandwidth 1 Gbps
                                                                        Unmetered</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> IP Address 1
                                                                        usable IP</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 2 x 480 GB SSD
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 99.9% uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 1Gbps@50TB</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Intel Graphics
                                                                        UHD 630</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> France</li>
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }} >New</div>
                                            </div>

                                        </div> */}
                                    </div>
                                </div>
                                <div className="tab-pane fade " id="nav-plan" role="tabpanel" aria-labelledby="nav-plan-tab">
                                    <div className="row row--15 mt_dec--40">
                                        {winrdp.map((rdp, index) => (
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className="rainbow-pricing style-aiwave">
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-one">{rdp.name}</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">${rdp.offer_price}</span>
                                                                <span className="text d-flex"><span style={{ textDecoration: "line-through" }}>${rdp.price}</span>/Per
                                                                    Month</span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default btn-border" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.user} user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.processor}
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.cpu}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.free}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.os}
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.ram}RAM</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>Bandwidth {rdp.bandwidth}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>IP Address {rdp.ip}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.storeage}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.uptime} uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.user}
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.description}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.location}</li>
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        ))};
                                        
                                        {/* <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className="rainbow-pricing style-aiwave active">
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-two">Shared RDP #1</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">$3.99</span>
                                                                <span className="text d-flex"><span style={{ textDecoration: "line-through" }}>$9.99</span>/Per
                                                                    Month</span>

                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default color-blacked" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i> 12-15 Max user
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Intel® Core i9
                                                                        9900K Processor</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Cores 8 Threads
                                                                        16 @3.60 GHz CPU</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Pre-Installed
                                                                        Apps</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Windows Server
                                                                        2019</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 64Gb RAM</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Bandwidth 1Gbps
                                                                        Unmetered</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> IP Address 1
                                                                        Shared IPV4</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 50Gb NVMe</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 99.9 uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> No Admin Acess
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Instant
                                                                        Activation</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> France</li>
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className="rainbow-pricing style-aiwave">
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-three">USA ADMIN RDP #1</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">$7.99</span>
                                                                <span className="text d-flex"><span
                                                                    style={{ textDecoration: "line-through" }}>$12.99</span>/Per
                                                                    Month</span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default btn-border" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i> Single user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Dual Intel/AMD
                                                                        Ryzen Processor</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 1 core CPU</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Instant Setup or
                                                                        Up to 12hrs</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Windows or Linux
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 1Gb RAM</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Bandwidth 1Gbps
                                                                        Metered</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> IP Address 1
                                                                        Dedicated IPV4</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 25Gb SSD/NVMe
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> 99.9% uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Full Admin Access
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> Dedicated Control
                                                                        panel</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> New York/Dallas
                                                                    </li>
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade " id="nav-year" role="tabpanel" aria-labelledby="nav-year-tab">
                            <div className="row">
                                <div className="col-lg-12">
                                    <nav className="aiwave-tab">
                                        <div className="tab-btn-grp nav nav-tabs text-center justify-content-center" id="nav-tab"
                                            role="tablist">
                                            <button className="nav-link active" id="nav-home1-tab" data-bs-toggle="tab"
                                                data-bs-target="#nav-home1" type="button" role="tab" aria-controls="nav-home1"
                                                aria-selected="false">
                                                Popular Cloud VPS Plans
                                            </button>
                                            <button className="nav-link with-badge " id="nav-profile1-tab" data-bs-toggle="tab"
                                                data-bs-target="#nav-profile1" type="button" role="tab" aria-controls="nav-profile1"
                                                aria-selected="true">
                                                Popular Dedicated Server Plans
                                            </button>
                                            <button className="nav-link with-badge " id="nav-profile1-tab" data-bs-toggle="tab"
                                                data-bs-target="#nav-plan1" type="button" role="tab" aria-controls="nav-plan1"
                                                aria-selected="true">
                                                Popular RDP Plans
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="tab-content p-0 bg-transparent border-0 border bg-light" id="nav-tabContent">
                                <div className="tab-pane fade active show" id="nav-home1" role="tabpanel" aria-labelledby="nav-home1-tab">
                                    {/* <h4 className="title w-600 mb--40 text-center">Explore to our smart Cloud VPS plans</h4> */}
                                    <div className="row row--15 mt_dec--40">
                                    {cloudvps.map((vps, index)=> (
                                            <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className={`rainbow-pricing style-aiwave`}>
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-one">{vps.name}</h4>
                                                            <div className="pricing">
                                                               
                                                                <span className="price-text">${(vps.offer_price * 12 ).toFixed(2)} </span>
                                                                <span className="text d-flex"><span
                                                                    style={{ textDecoration: "line-through" }}>${vps.price * 12}</span>/Per
                                                                    Year</span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default btn-border" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="coupon-card">
                                                            <div className="coupon-row">
                                                                <span id="cpnCode">STEALDEAL20</span>
                                                                <span id="cpnBtn" onClick={() => handleCopy("STEALDEAL20", setBtnText)}>
                                                                    {btnText}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i> {vps.users} user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> {vps.processor}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> {vps.cpu}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> {vps.traffic}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.os}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.ram}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.bandwidth}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> IP Address  {vps.ip}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.drives}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i> {vps.uptime} uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.description}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>  {vps.location}</li>
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="feature-badge" style={{ background: "#00ac69", color: " #fff" }} >{(vps.tag).charAt(0).toUpperCase() + vps.tag.slice(1).toLowerCase()}</div>
                                            </div>
                                        </div>
                                        ))};
                                       
                                    </div>
                                </div>
                                <div className="tab-pane fade " id="nav-profile1" role="tabpanel" aria-labelledby="nav-profile1-tab">
                                    <div className="row row--15 mt_dec--40">
                                    {dedicated.map((dedicate, index) => (
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className="rainbow-pricing style-aiwave">
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-one">{dedicate.name}</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">${(dedicate.offer_price * 12).toFixed(2) }</span>
                                                                <span className="text d-flex"><span style={{ textDecoration: "line-through" }}>${parseFloat(dedicate.price) * 12 }</span>/Per
                                                                    Year</span>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default btn-border" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.users} user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.processor}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>Logical Cores: {dedicate.cpu}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.traffic}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.os}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.ram}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>IP Address{dedicate.ip}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.drives}
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.uptime}uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.description}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{dedicate.location}</li>
                                                                    {/* <li><i className="fa-regular fa-circle-check"></i></li> */}
                                                                    
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="feature-badge" style={{ background: "#00ac69", color: "#fff" }} >{(dedicate.tag).charAt(0).toUpperCase() + dedicate.tag.slice(1).toLowerCase()}</div>
                                            </div>
                                        </div>
                                        ))};
                                       
                                    </div>
                                </div>
                                <div className="tab-pane fade " id="nav-plan1" role="tabpanel" aria-labelledby="nav-plan1-tab">
                                    <div className="row row--15 mt_dec--40">
                                    {winrdp.map((rdp, index) => (
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-12 mt--40">
                                            <div className="rainbow-pricing style-aiwave">
                                                <div className="pricing-table-inner">
                                                    <div className="pricing-top">
                                                        <div className="pricing-header">
                                                            <h4 className="title color-var-one">{rdp.name}</h4>
                                                            <div className="pricing">
                                                                <span className="price-text">${(rdp.offer_price * 12).toFixed(2) }</span>
                                                                <span className="text d-flex"><span style={{ textDecoration: "line-through" }}>${parseFloat(rdp.price) * 12 }</span>/Per
                                                                    Year</span>
                                                            </div>
                                                        </div>
                                                        <div className="pricing-footer">
                                                            <a className="btn-default btn-border" href="/">Buy Now</a>
                                                        </div>
                                                        <div className="pricing-body">
                                                            <div className="features-section has-show-more">
                                                                <h6>Features</h6>
                                                                <ul className="list-style--1 has-show-more-inner-content">
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.user} user</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.processor}
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.cpu}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.free}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.os}
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.ram}RAM</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>Bandwidth {rdp.bandwidth}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>IP Address {rdp.ip}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.storeage}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.uptime} uptime</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.user}
                                                                    </li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.description}</li>
                                                                    <li><i className="fa-regular fa-circle-check"></i>{rdp.location}</li>
                                                                </ul>
                                                                <div className="rbt-show-more-btn">Show More</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        ))};
                                        
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* testimonial */}
            <div className="rainbow-testimonial-area rainbow-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-left" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                <h4 className="subtitle">
                                    <span className="theme-gradient">Testimonials</span>
                                </h4>
                                <h2 className="title mb--60">
                                    The opinions of the community
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Testimonial />
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Testimonial Area  --> */}

            {/* <!-- Start blog Area  --> */}
            <div className="rainbow-testimonial-area rainbow-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-left" data-sal="slide-up" data-sal-duration="400"
                                data-sal-delay="150">
                                <h4 className="subtitle">
                                    <span className="theme-gradient">Blogs</span>
                                </h4>
                                <h2 className="title mb--60">Explore Our Insights</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                <BlogSlider />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End blog Area  --> */}

            <Location />

            <Footer />

        </Fragment>
    );
}

export default Home;
