import React, { Fragment,useState, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import BlogSlider from "../../slider/BlogSlider";
import Testimonial from "../../slider/testimonial";
import axios from "axios";
import { useParams } from 'react-router-dom';
import Promo from "../../slider/Promo";
import Location from "../../sales-page/Location";
const assets = '/assets';

// eslint-disable-next-line
function BlogDetails({}) {
    const [blog, setBlog] = useState([null]);
    const { slug } = useParams();
    const [loading, setLoading] = useState(true);
    console.log(slug); 

    
    useEffect(() => {
        const fetchBlog = async () => {
          setLoading(true); // Set loading state before the fetch
          try {
            const response = await axios.get(`https://admin.digirdp.chaipost.co.in/api/blog/${slug}`);
            console.log("API Response:", response.data);
            setBlog(response.data); // Update the blog state with response data
          } catch (error) {
            console.error("Error fetching blog data:", error);
            setBlog(null); // Handle error by setting the blog to null
          } finally {
            setLoading(false); // End loading state
          }
        };
        
        if (slug) {
          fetchBlog();
          console.log(blog)
        }
      }, [slug]);

      
    return (
        <Fragment>
            <Header />
            {/* <!-- Start Pricing Area  --> */}
            <div className="main-content">
                {/* <!-- Start Breadcarumb area  --> */}
                <div className="breadcrumb-area breadcarumb-style-1 pt--180 pb--100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h3 className="title h3">{blog.title} <br /> Here’s How</h3>
                                    <ul className="page-list">
                                        <li className="rainbow-breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="rainbow-breadcrumb-item"><a href="/">Blog</a></li>
                                        <li className="rainbow-breadcrumb-item active">Blog Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Breadcarumb area  -->
                <!-- Start Pricing Style-2  --> */}
                <div className="rainbow-pricing-area rainbow-section-gap">
                    <div className="container-fluid">

                        <Promo />

                        {/* <!-- Start Blog Area  --> */}
                        <div className="rainbow-blog-section bg-color-1 ">
                            <div className="container container-new">
                                <div className="row row--30">
                                    <div className="col-lg-8">
                                        <div className="rainbow-blog-details-area">
                                            <div className="post-page-banner">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="content text-center">
                                                                <div className="thumbnail"><img className="w-100 radius" src={`${assets}/images/added/big-one.png`}  alt="Blog Images" /></div>
                                                                <ul className="rainbow-meta-list">
                                                                    <li>
                                                                        <i className="feather-user"></i>
                                                                        <a href="/"> {blog.user_name}</a>
                                                                    </li>
                                                                    <li>
                                                                        <i className="feather-calendar"></i>
                                                                        Nov-12-24
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="blog-details-content pt--40">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="content">
                                                                <h2 className="title">{blog.title} Here’s How</h2>
                                                                <div dangerouslySetInnerHTML={{__html:blog.description}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt_md--40 mt_sm--40">
                                        <aside className="rainbow-sidebar">
                                            <div className="rbt-single-widget widget_search mt--40">
                                                <div className="inner">
                                                    <form className="blog-search" action="#"><input type="text" placeholder="Search ..." />
                                                        <button className="search-button">
                                                            <i className="feather-search"></i>
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="rbt-single-widget widget_recent_entries mt--40">
                                                <h3 className="title">Post</h3>
                                                <div className="inner">
                                                    <ul>
                                                        <li>
                                                            <div className="list-blog-sm">
                                                                <div className="img">
                                                                    <img src={`${assets}/images/added/bd-1.jpg`} alt="Blog" />
                                                                </div>
                                                                <div className="content">
                                                                    <a className="d-block" href="/how-to-login-to-windows">How to Login to Windows RDP: A Comprehensive Guide for All Operating Systems</a>
                                                                </div>
                                                            </div>

                                                        </li>
                                                        <li>
                                                            <div className="list-blog-sm">
                                                                <div className="img">
                                                                    <img src={`${assets}/images/added/bd-2.png`} alt="Blog" />
                                                                </div>
                                                                <div className="content">
                                                                    <a className="d-block" href="/">
                                                                    Using Amazon MTurk with RDP: Boost Your Productivity with DigiRDP's USA Plans</a>
                                                                </div>
                                                            </div>

                                                        </li>
                                                        <li>
                                                            <div className="list-blog-sm">
                                                                <div className="img">
                                                                    <img src={`${assets}/images/added/bd-3.png`} alt="Blog" />
                                                                </div>
                                                                <div className="content">
                                                                    <a className="d-block" href="/">How to Add Windows Templates to Virtualizor</a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="list-blog-sm">
                                                                <div className="img">
                                                                    <img src={`${assets}/images/added/bd-4.png`} alt="Blog" />
                                                                </div>
                                                                <div className="content">
                                                                    <a className="d-block" href="/">Fixing 'Storage Permission Denied' Error in Virtualizor VPS: Adjusting User and Group Settings</a>
                                                                </div>
                                                            </div>
                                                        </li> 
                                                        <li>
                                                            <div className="list-blog-sm">
                                                                <div className="img">
                                                                    <img src={`${assets}/images/added/bd-5.png`} alt="Blog" />
                                                                </div>
                                                                <div className="content">
                                                                    <a className="d-block" href="/">How to Install Ubuntu 22.04 Using IPMI on a Server</a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="list-blog-sm">
                                                                <div className="img">
                                                                    <img src={`${assets}/images/added/bd-6.png`} alt="Blog" />
                                                                </div>
                                                                <div className="content">
                                                                    <a className="d-block" href="/">Want to Host Multiple Website on One Server? Here’s How</a>
                                                                </div>
                                                            </div>
                                                        </li> 
                                                    </ul>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start Features Area --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient"></span>
                                            </h4>
                                            <h2 className="title w-600 mb--20">Our Plans</h2>
                                            <p>There are several plans which includes Windows RDP, Cloud VPS and Dedicated <br /> servers which will make the deployement of your application more easier</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt--60">
                                        <div className="advance-tab-button advance-tab-button-1 right-top">
                                            <ul className="nav nav-tabs tab-button-list">

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button ">
                                                        <div className="tab">
                                                            <h2 className="title">240 + </h2>
                                                            <p className="description sal-animate">Windows RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">110 +</h2>
                                                            <p className="description sal-animate">Cloud VPS Plans</p>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">59 +</h2>
                                                            <p className="description sal-animate">Dedicated Server Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li className="col-lg-3 nav-item" role="presentation">
                                                    <a href="/" className="nav-link tab-button">
                                                        <div className="tab">
                                                            <h2 className="title">114 +</h2>
                                                            <p className="description sal-animate">Private RDP Plans</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="view-more-button text-center mt--35 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                        <a className="btn-default color-blacked" href="/contact">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Start CTA Style-one Area  --> */}
                        <div className="rainbow-rn-cta mt-5">
                            <div className="container">
                                <div className="row row--0 align-items-center content-wrapper">
                                    <div className="col-lg-8">
                                        <div className="inner">
                                            <div className="content text-left">
                                                <h4 className="title sal-animate" data-sal="slide-up" data-sal-duration="400"
                                                    data-sal-delay="200">Join our Experts community</h4>
                                                <p className="sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">Become part of a thriving network of professionals! Share knowledge, solve challenges, and grow together with industry experts.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="right-content">
                                            <div className="call-to-btn text-start text-lg-end sal-animate" data-sal="slide-up"
                                                data-sal-duration="400" data-sal-delay="400">
                                                <div className="team-image">
                                                    <img src={`${assets}/images/cta-img/team-01.png`} alt="Group" />
                                                </div>
                                                <a className="btn-default" href="/contact">Join DigiRDP Experts</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-shape">
                                        <img src={`${assets}/images/cta-img/bg-shape-01.png`} alt="BG Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* testimonial */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Testimonials</span>
                                            </h4>
                                            <h2 className="title mb--60">
                                                The opinions of the community
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Testimonial />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Testimonial Area  --> */}

                        {/* <!-- Start blog Area  --> */}
                        <div className="rainbow-testimonial-area rainbow-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center sal-animate" data-sal="slide-up" data-sal-duration="400"
                                            data-sal-delay="150">
                                            <h4 className="subtitle">
                                                <span className="theme-gradient">Blogs</span>
                                            </h4>
                                            <h2 className="title mb--60">Explore Our Insights</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="service-wrapper rainbow-service-slider-actvation slick-grid-15 rainbow-slick-dot rainbow-gradient-arrows">
                                            <BlogSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="view-more-button text-center mt--45 sal-animate" data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                                <a className="btn-default color-blacked" href="/blog">View Plans <i className="fa-sharp fa-light fa-arrow-right ml--5"></i></a>
                            </div>
                        </div>
                        {/* <!-- End blog Area  --> */}

                    </div>
                </div>
                {/* <!-- End Pricing Style-2  --> */}
            </div>
            {/* <!-- Start Pricing Area  --> */}

            <Location />

            <Footer />
        </Fragment>
    );
}

export default BlogDetails;