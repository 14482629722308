import React, { Fragment,useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
const assets = '/assets';

const BlogSlider = () => {
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Default for desktop
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        customPaging: (i) => {
            if (i < 3) { // Only show dots for the first three slides
                return <button>{i + 1}</button>;
            }
            // Instead of returning null, ensure you handle other cases properly
            return <span style={{ display: 'none' }} />;
        },
        responsive: [
            {
                breakpoint: 1200, // For large screens (desktop)
                settings: {
                    slidesToShow: 3,  // Show 3 slides
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992, // For tablets
                settings: {
                    slidesToShow: 2,  // Show 2 slides
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // For small devices (mobile)
                settings: {
                    slidesToShow: 1,  // Show 1 slide
                    slidesToScroll: 1,
                },
            },
        ],
    };
    

    const [blogs, setBlogs] = useState([]);
   

    useEffect(() => {
        // Fetch slider data from Laravel API
        axios
          .get("https://admin.digirdp.chaipost.co.in/api/DBblog")
          .then((response) => {
            setBlogs(response.data[1]);
          })
          .catch((error) => {
            console.error("Error fetching slider data:", error);
          });
      }, []);





    return (
        <Fragment>

            <Slider {...sliderSettings}>
              {blogs
               .filter(blog => blog.home === 1)
              .map((blog)=>(  
                <div className="slide-single-layout">
                    <div className="rainbow-card undefined">
                        <div className="inner">
                            <div className="thumbnail">
                                <a className="image" href="/blog-details"><img src={`${assets}/images/added/blog-3.jpg`} alt="Blog" />
                                </a>
                            </div>
                            <div className="content">
                                <ul className="rainbow-meta-list">
                                    <li><i className="fa-sharp fa-regular fa-calendar-days icon-left"></i>{new Date(blog.created_at).toLocaleDateString()}</li>
                                    <li className="separator"></li>
                                    <li className="catagory-meta"><a href="/">{blog.user_name}</a></li>
                                </ul>
                                <h4 className="title"><a href="/blog-details">{blog.title}</a></h4>
                                    {/* <p className="description"><div dangerouslySetInnerHTML={{__html:blog.description.substring(0,50)}} /> </p> */}
                                <Link className="btn-read-more border-transparent"  to={`/blog-details/${blog.slug}`}><span>Read More <i className="fa-sharp fa-regular fa-arrow-right"></i></span></Link>
                            </div>
                        </div>
                    </div>
                </div>))}
                {/* <div className="slide-single-layout">
                    <div className="rainbow-card undefined">
                        <div className="inner">
                            <div className="thumbnail">
                                <a className="image" href="/blog-details"><img src="assets/images/added/blog-2.jpg" alt="Blog" />
                                </a>
                            </div>
                            <div className="content">
                                <ul className="rainbow-meta-list">
                                    <li><i className="fa-sharp fa-regular fa-calendar-days icon-left"></i> 10 Dec 2023</li>
                                    <li className="separator"></li>
                                    <li className="catagory-meta"><a href="/">B. MISHRA</a></li>
                                </ul>
                                <h4 className="title"><a href="/blog-details">Benefits, Uses, Challenges, and Cost of GPU Encoding RDP and GPU Render RDP</a></h4>
                                <p className="description">The rapid pace of technological advancement has revolutionized the way we work and play, with ...</p>
                                <a className="btn-read-more border-transparent" href="/"><span>Read More <i className="fa-sharp fa-regular fa-arrow-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-card undefined">
                        <div className="inner">
                            <div className="thumbnail">
                                <a className="image" href="/blog-details"><img src="assets/images/added/blog-3.jpg" alt="Blog" />
                                </a>
                            </div>
                            <div className="content">
                                <ul className="rainbow-meta-list">
                                    <li><i className="fa-sharp fa-regular fa-calendar-days icon-left"></i> 10 Dec 2023</li>
                                    <li className="separator"></li>
                                    <li className="catagory-meta"><a href="/">B. MISHRA</a></li>
                                </ul>
                                <h4 className="title"><a href="/blog-details">Streaming RDP vs Encoding RDP: Which Remote Desktop Service is Right for You?</a></h4>
                                <p className="description">Remote desktop services have become a necessity in today's digital world where people are ...</p>
                                <a className="btn-read-more border-transparent" href="/"><span>Read More <i className="fa-sharp fa-regular fa-arrow-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-card undefined">
                        <div className="inner">
                            <div className="thumbnail">
                                <a className="image" href="/blog-details"><img src="assets/images/added/blog-5.jpg" alt="Blog" />
                                </a>
                            </div>
                            <div className="content">
                                <ul className="rainbow-meta-list">
                                    <li><i className="fa-sharp fa-regular fa-calendar-days icon-left"></i> 10 Dec 2023</li>
                                    <li className="separator"></li>
                                    <li className="catagory-meta"><a href="/">B. MISHRA</a></li>
                                </ul>
                                <h4 className="title"><a href="/blog-details">Europe RDP Coupons and Promo Codes for 2024: UK-NL-Germany and more</a></h4>
                                <p className="description">In the dynamic landscape of today’s digital age, accessibility, security, and efficiency... </p>
                                <a className="btn-read-more border-transparent" href="/"><span>Read More <i className="fa-sharp fa-regular fa-arrow-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide-single-layout">
                    <div className="rainbow-card undefined">
                        <div className="inner">
                            <div className="thumbnail">
                                <a className="image" href="/blog-details"><img src="assets/images/added/blog-6.jpg" alt="Blog" />
                                </a>
                            </div>
                            <div className="content">
                                <ul className="rainbow-meta-list">
                                    <li><i className="fa-sharp fa-regular fa-calendar-days icon-left"></i> 10 Dec 2023</li>
                                    <li className="separator"></li>
                                    <li className="catagory-meta"><a href="/">B. MISHRA</a></li>
                                </ul>
                                <h4 className="title"><a href="/blog-details">Exclusive 2024 Residential RDP Discounts & Promo Codes: US/UK/NL/IN & More!</a></h4>
                                <p className="description">In today's digital age, having a reliable and secure internet connection is essential for ...</p>
                                <a className="btn-read-more border-transparent" href="/"><span>Read More <i className="fa-sharp fa-regular fa-arrow-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>  */}
            </Slider>
            {/* <!-- End Testimonial Area  --> */}
        </Fragment>
    );
}

export default BlogSlider;
